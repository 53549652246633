import "./WebSocket.css";
import { useDispatch, useSelector } from 'react-redux';
import { useState, useRef, useEffect } from 'react';
import { setWsStatus, setWsIsSetUp, setwsTimeToSendReady, setwsDataMSG, setBattleInvitation, reject_battleInvitation, unsetFriendForWait, reject_battleInvitationId, battleInvitationOff, setGame, setP1gameReady, setP2gameReady, setAcceptInvite } from '../../Gdata';
import Api from "../../../services/Api";

function WebSocketContainer({ ready, refx }) {

    //перепроверить почему разрыв идёт каждые 10 секунд!!!!!!!!!!!!!!!!s!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    const dispatch = useDispatch();
    const ws = useRef(null);

    let url = Api.getUrl("ws");

    const ws_token = useSelector(state => state.userInfo.ws_token);
    const ws_obj = useSelector(state => state.webSocket);
    const battleInvitation = useSelector(state => state.battleInvitation);
    const userInfo = useSelector(state => state.userInfo);
    const store = useSelector(state => state.store.owned);
    const gameData = useSelector(state => state.game);
    const amIplayer1 = useSelector(state => state.amIfirstPlayer);
    const acceptInvite = useSelector(state => state.acceptInvite);
    const wsCanvasData = useSelector(state => state.wsCanvasData);
    const wsDataMSG = useSelector(state => state.wsDataMSG);
    const wsTimeToSendReady = useSelector(state => state.wsTimeToSendReady);
    const wsStatus = useSelector(state => state.wsStatus);
    const wsIsSetUp = useSelector(state => state.wsIsSetUp);


    useEffect(() => {
        if (ready) {
            connectWs();
            dispatch(setWsIsSetUp(true));
        }
        else {
            if (wsIsSetUp) {
                closeWs();
            }
        }
    }, [ready]);


    function connectWs() {
        //console.log("ws_token", ws_token)
        ws.current = new WebSocket(url + ws_token);
        ws.current.addEventListener('open', wsOpen);
        ws.current.addEventListener('message', wsMsg);
        ws.current.addEventListener('close', closeMsg);
        ws.current.addEventListener('error', wsError);
    }

    function closeWs() {
        ws.current.removeEventListener('open', wsOpen);
        ws.current.removeEventListener('message', wsMsg);
        ws.current.removeEventListener('close', closeMsg);
        ws.current.removeEventListener('error', wsError);
    }

    function wsOpen() {
        dispatch(setWsStatus("Соединение открыто"));
    }

    function wsError() {
        dispatch(setWsStatus("Соединение Ошибка"));
    }

    const wsMsg = (e) => {
        const message = JSON.parse(e.data);
        //console.log("ws получил", message);
        let o;

        switch (message.action) {
            case "invite_to_game":
                o = {
                    id: message.profile.id,
                    level: message.profile.level,
                    nick: message.profile.nick
                };

                dispatch(setBattleInvitation(o));
                break;
            case "reject_invite":
                dispatch(unsetFriendForWait());
                break;
            case "timeout_invite":
                dispatch(reject_battleInvitationId(message.player_from.user_id));
                break;
            case "start_game":
                //снимаем лист ожидания----------------
                //чистим лист приглашений--------------
                dispatch(battleInvitationOff());
                dispatch(unsetFriendForWait());
                //блокируем себя для приглашений-------     
                console.log("это важно!!!", message);
                dispatch(setwsDataMSG(message));
                //переходим в режим игры--------------- 
                break;
            case "game_is_load":
                //console.log("-----------------wsTimeToSendReady",wsTimeToSendReady)            
                dispatch(setwsTimeToSendReady());
                break;
            case "open_question":
                o = {
                    data: "open_question",
                    cell_number: message.cell_number,
                };

                if (refx.current != null) {
                    if (refx.current.contentWindow != null) {
                        refx.current.contentWindow.postMessage(o, "*");
                    }
                }
                break;
            case "message":
                o = {
                    data: "custom_com",
                    payload: message.data,
                };
                if (refx.current != null) {
                    if (refx.current.contentWindow != null) {
                        refx.current.contentWindow.postMessage(o, "*");
                    }
                }
                break;
            default: break;
        }
    }

    useEffect(() => {

        //console.log("game_is_load", gameData.myPNum)

        if (wsTimeToSendReady > 0) {

            if (gameData.myPNum == 1) {
                console.log("диспач 2");
                dispatch(setP2gameReady());
            } else {
                console.log("диспач 1");
                dispatch(setP1gameReady());
            }
        }

    }, [wsTimeToSendReady]);

    useEffect(() => {

        //console.log("!!!XXX!!! теперь дошёл до сюда 2", wsCanvasData);

        if (wsCanvasData > 0) {

            console.log("!!!XXX!!! userInfo, wsDataMSG", userInfo, wsDataMSG);

            if (wsDataMSG.profile == undefined) {

                let oPC = {
                    inGame: true,
                    myId: userInfo.id,
                    otherId: -1,
                    mode: "pc",
                    player1: {
                        id: userInfo.id,
                        nick: userInfo.nick,
                        level: userInfo.level,
                        health: userInfo.grow_branches.length * (-1) + 3,
                        store: store,
                    },
                    player2: {
                        id: -1,
                        nick: "Bender",
                        level: userInfo.level,
                        health: 3,
                        store: [],
                    },
                    p1gameReady: true,
                    p2gameReady: false,
                    myPNum: 1,
                    bothStart: false,
                    questions: wsDataMSG.questions,
                    hash: wsDataMSG.game_hash,
                };

                dispatch(setGame(oPC));
            }
            else {
                let o = {
                    inGame: true,
                    myId: userInfo.id,
                    otherId: wsDataMSG.profile.id,
                    mode: "player",
                    player1: null,
                    player2: null,
                    p1gameReady: false,
                    p2gameReady: false,
                    myPNum: 1,
                    bothStart: false,
                    questions: wsDataMSG.questions,
                    hash: wsDataMSG.game_hash,
                };

                if (amIplayer1) {
                    //я игрок 1
                    o.player1 = {
                        id: userInfo.id,
                        nick: userInfo.nick,
                        level: userInfo.level,
                        health: userInfo.grow_branches.length * (-1) + 3,
                        store: store,
                    }
                    o.player2 = {
                        id: wsDataMSG.profile.id,
                        nick: wsDataMSG.profile.nick,
                        level: wsDataMSG.profile.level,
                        health: wsDataMSG.profile.grow_branches.length * (-1) + 3,
                        store: wsDataMSG.profile.bought_goods
                    }

                    console.log("я 1 игрок");

                } else {
                    //я игрок 2
                    o.player2 = {
                        id: userInfo.id,
                        nick: userInfo.nick,
                        level: userInfo.level,
                        health: userInfo.grow_branches.length * (-1) + 3,
                        store: store,
                    }
                    o.player1 = {
                        id: wsDataMSG.profile.id,
                        nick: wsDataMSG.profile.nick,
                        level: wsDataMSG.profile.level,
                        health: wsDataMSG.profile.grow_branches.length * (-1) + 3,
                        store: wsDataMSG.profile.bought_goods
                    }

                    console.log("я 2 игрок");
                    o.myPNum = 2;
                }

                dispatch(setGame(o));
            }
        }

    }, [wsCanvasData]);


    function closeMsg() {
        console.log("Соединение закрыто");
        dispatch(setWsStatus("Соединение закрыто"));
        closeWs();
        connectWs();
    }

    function sendMsg(json) {

        if (wsStatus == "Соединение открыто") {
            ws.current.send(JSON.stringify(json));
        }
    }

    useEffect(() => {

        let id, o;

        switch (ws_obj.com) {
            case "send_invite":

                //console.log("отправить приглашение", ws_obj.payload);

                sendMsg({
                    "action": "send_invite",
                    "player_to": {
                        "user_id": ws_obj.payload
                    }
                });
                break;
            case "ping":
                sendMsg({
                    "action": "ping",
                });
                break;
            case "reject_invite":

                id = battleInvitation[0].id;

                sendMsg({
                    "action": "reject_invite",
                    "player_to": {
                        "user_id": id
                    }
                });

                dispatch(reject_battleInvitation());

                break;

            case "timeout_invite":

                //console.log("ws_obj.payload", ws_obj.payload);

                sendMsg({
                    "action": "timeout_invite",
                    "player_to": {
                        "user_id": ws_obj.payload
                    }
                });

                dispatch(unsetFriendForWait());
                break;

            case "accept_invite":

                console.log("acceptInvite", acceptInvite);
                if (acceptInvite) {
                    return;
                } else {
                    dispatch(setAcceptInvite(true));
                }

                id = battleInvitation[0].id;

                sendMsg({
                    "action": "accept_invite",
                    "player_to": {
                        "user_id": id
                    }
                });

                break;

            case "game_is_load":

                sendMsg({
                    "action": "game_is_load",
                    "player_to": {
                        "user_id": ws_obj.payload
                    }
                });

                break;

            case "open_question":

                sendMsg({
                    "action": "open_question",
                    "type_id": 1,
                    "player_to": {
                        "user_id": ws_obj.payload.user_id
                    },
                    "question": {
                        "index": 0
                    },
                    "cell_number": ws_obj.payload.cell_number
                });

                break;

            case "custom_com":

                sendMsg({
                    "action": "message",
                    "user_action": "custom_com",
                    "player_to": {
                        "user_id": ws_obj.payload.user_id
                    },
                    "data": ws_obj.payload.payload
                });
                break;
            case "otherPlayerExit":

                //console.log("GD OID", gameData.otherId);

                sendMsg({
                    "action": "message",
                    "user_action": "custom_com",
                    "player_to": {
                        "user_id": gameData.otherId
                    },
                    "data": JSON.stringify({ user_action: "otherPlayerExit" })
                });

                //Я ухожу
                //console.log("я ухожу");
                if (refx.current != null) {
                    if (refx.current.contentWindow != null) {
                        o = {
                            data: "IAmExit",
                        };
                        refx.current.contentWindow.postMessage(o, "*");
                    }
                }
                break;
            default: break;
        }

    }, [ws_obj.i]);

    /*
    function send() {
        ws.current.send(JSON.stringify({
            "action": "ping"
        }));
    }
    */

    /*
    return (
        <div className="WebSocket">
            Ready = {ready.toString()} <br />
            Status = {status}
        </div>
    )
    */
    return (<></>)


}

export default WebSocketContainer;