import "../Registration/Registration.css";
import "../Intro/Intro.css";
import "./NewPass.css";
import { useDispatch, useSelector } from 'react-redux';
import FContainer from "../../Base/FContainer/FContainer";
import GradientButtonMini from "../../Base/GradientButton/GradientButtonMini";
import { setAppSection, sendNewPass, resetNPError  } from '../../Gdata';
import { motion } from "framer-motion";
import { useState, useEffect } from 'react';


function NewPass() {

    const dispatch = useDispatch();

    const isMobile = useSelector(state => state.isMobile);    
    const setError = useSelector(state => state.newPassError);

    const newPass = useSelector(state => state.newPass);
    const mClass = isMobile ? "mobile" : "pc";

    const [inputTxt4, setTxt4] = useState("");
    const [inputTxt5, setTxt5] = useState("");

    const [errTxtv, setErrTxtv] = useState("f");
    const [errTxt, setErrTxt] = useState("");

    const [block, setBlock] = useState(true);

    const [isDone, setDone] = useState(false);


    useEffect(() => {
        console.log("errTxt", errTxt)
        if (errTxt !== "") {
            setErrTxtv("v");
        }

        if (setError !== "") {
            setErrTxt(setError);
        }

        if (setError == "done"){
            setDone(true);
        }
    }, [errTxt, setError]);

    useEffect(() => {

        if (inputTxt4 !== "" && inputTxt5 !== "") {
            setBlock(false);
        } else {
            setBlock(true);
        }
    }, [inputTxt4, inputTxt5]);




    const goReg = (e) => {
        // console.log("goNickName")
        e.preventDefault();

        console.log(inputTxt4, inputTxt5)

        if (inputTxt4.length < 8 || inputTxt5.length < 8) {
            setErrTxt("Ошибка: Пароль должен содержать минимум 8 знаков.");
            return;
        }

        if (inputTxt4 != inputTxt5) {
            setErrTxt("Ошибка: Пароли не совпадают.");
            return;
        }

        const o = {
            id: newPass.user_id,
            code: newPass.code,
            password1: inputTxt4,
            password2: inputTxt5,
        }

        dispatch(sendNewPass(o));

    }

    const goBack = (e) => {
        // console.log("goNickName")
        e.preventDefault();
        dispatch(setAppSection("login"));
        dispatch(resetNPError());
    }

    const form1 = (
        <>
            <div className="regTxt2">Введите новый пароль</div>
            <div className="regTxt">Пароль</div>
            <div><input className={"reg-input-txt reg-input-txt-" + mClass} type="password" id="pass" name="pass" onChange={newValue => { setTxt4(newValue.target.value.trim()); }} /></div>
            <div className="regTxt">Подтверждение пароля</div>
            <div><input className={"reg-input-txt reg-input-txt-" + mClass} type="password" id="pass2" name="pass2" onChange={newValue => { setTxt5(newValue.target.value.trim()); }} /></div>
            <div className={"regTxt3 regTxt3v" + errTxtv}>{errTxt}</div>
            <div className={"marginRegBtnNP-" + mClass}></div>
            <GradientButtonMini block={block} text="Сохранить" onClick={goReg}></GradientButtonMini>
        </>
    )

    const form2 = (
        <>
            <div className="marginRegBtnNP2"></div>
            <div className="regTxt2">Ваш пароль был успешно изменен.</div>
            <div className="marginRegBtnNP2"></div>
            <GradientButtonMini block={block} text="Назад" onClick={goBack}></GradientButtonMini>
        </>
    )



    const mView = (
        <div id="intro">

            <div className={"intro-logo intro-logo-" + mClass}></div>
            <div className="marginRegBtnNP2"></div>

            {
                (isDone) ? form2 : form1
            }


        </div>
    )

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }}
        >
            <FContainer>
                {mView}
            </FContainer>
        </motion.div>
    )
}

export default NewPass;