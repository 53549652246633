import "./RatingItem.css";
import { useSelector } from 'react-redux';
import Engine from "../../../services/Engine";


function RatingItem({ num, userName, level, score }) {

    const isMobile = useSelector(state => state.isMobile);
    const mClass = isMobile ? "mobile" : "pc";

    return (
        <div className="ratingCard">
            <div className="ratingCard_cont" >


                <div className="ratingCard_num">{num + 1}
                </div>

                <div className="ratingCard_photo"></div>
                <div className="ratingCard_txt">
                    <div className="ratingCard_txt-t">{userName}</div>
                    <div className="ratingCard_txt-t">{level + " уровень"} </div>
                </div>
                <div className="ratingCard_gap"> </div>
                <div className="ratingCard_txt-score">
                    {score + " " + Engine.num_word(score, ["балл", "балла", "баллов"])}
                </div>

            </div>
            <div className="ratingCard_Bottom"></div>
        </div>
    )
}

export default RatingItem;