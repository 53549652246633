import './CanvasTree.css';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRef } from 'react';



function CanvasTree({ height, mode }) {

    const iframeRef = useRef(null);

    const userInfo = useSelector(state => state.userInfo);
    const addFruit = useSelector(state => state.addFruit);
    const pmTreeData = useSelector(state => state.pmTreeData);
    const owned = useSelector(state => state.store.owned);

    const health = 3-userInfo.grow_branches.length;    

    const titleAnimation = useSelector(state => state.titleAnimation);

    let iframeSrc = "./canvastree/index.html";

    if (mode == "profile") {
        switch (titleAnimation) {
            case "levelUp":
                iframeSrc = "./animation/levupto" + userInfo.level + "/index.html";
                break;
            case "levelDown":
                iframeSrc = "./animation/levdownto" + userInfo.level + "/index.html";
                break;
            default:
                iframeSrc = "./canvastree/index.html"
                break;
        }
    }

    useEffect(() => {
        if (pmTreeData.indexOf("treeStart") > -1) {
            var o = {
                data: "set tree",
                user: {
                    level: userInfo.level,
                    fruit: userInfo.fruit,
                    health: health,
                    grow: userInfo.grow_branches,
                    titleAnimation: titleAnimation,
                    addFruit: addFruit,
                },
                arr: owned,
                mode: mode
            };

            iframeRef.current.contentWindow.postMessage(o, "*");
        }
    }, [pmTreeData, userInfo, owned]);


    return (
        <iframe title="canvastree" className="iframeCanvastree" height={height} src={iframeSrc} ref={iframeRef} />
    )
}

export default CanvasTree;