import "./FriendWaiter.css";
import "../Button/Button.css"
import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import Engine from "../../../services/Engine";
import { useDispatch } from 'react-redux';
import { unsetFriendForWait, setWSAction } from '../../Gdata';
import { useSelector } from 'react-redux';

function FriendWaiter() {

    const fw = useSelector(state => state.friend);

    console.log("FW", fw)

    const dispatch = useDispatch();

    function exit() {
        console.log("fw.info.id", fw.info.id);
        dispatch(setWSAction({ com: "timeout_invite", payload: fw.info.id }));
    }

    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {            
            setSeconds(seconds => seconds + 1);
        }, 1000);
        return () =>  clearInterval(interval); 
    }, []);


    useEffect(() => {

        console.log("seconds", seconds);

        if (seconds >= 120) {//120
            exit();
        }


    }, [seconds]);


    function secondsToTime(secs) {
        var hours = Math.floor(secs / (60 * 60));
        var divisor_for_minutes = secs % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60) + hours * 60;
        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);
        var obj = {
            h: hours,
            m: minutes,
            s: seconds
        };
        return obj.m + " " + Engine.num_word(obj.m, ["минута", "минуты", "минут"]) + " " + obj.s + " " + Engine.num_word(obj.s, ["секунда", "секунды", "секунд"]);
    }


    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }} className="friendWaiter">
            <div className="friendWaiter_window">

                <div className="friendWaiter_window_exit btn" onClick={exit}></div>

                <div className="friendWaiter_window_img2_container">
                    <motion.div
                        animate={{ rotate: 180 }}
                        transition={{ ease: "linear", repeat: Infinity, duration: 5 }}
                        className="friendWaiter_window_img2">
                    </motion.div>
                </div>

                <div className="friendWaiter_window_title"></div>
                <motion.div
                    animate={{ y: [-6, 6] }}

                    transition={{ ease: "linear", repeat: Infinity, duration: 1, repeatType: "mirror" }}
                    className="friendWaiter_window_img1">
                </motion.div>

                <div className="friendWaiter_window_txt">{secondsToTime(seconds)}</div>


            </div>
        </motion.div>
    )
}

export default FriendWaiter;