import "./Profile.css";
import "../../Base/Button/Button.css";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux';
import FriendFinder from "../../Base/FriendFinder/FriendFinder";
import { useState, useEffect } from 'react';
import FriendWaiter from "../../Base/FriendWaiter/FriendWaiter";
import Engine from "../../../services/Engine";
import { startWithPc, sProfile2, setAppSection, getOnlineUsers, battleInvitationOff, unsetFriendForWait, addBranch } from '../../Gdata';
import CanvasTree from "../../Base/CanvasTree/CanvasTree";
import ScaleDiv from "../../Base/ScaleDiv/ScaleDiv";

function Profile({ w, h }) {

    const dispatch = useDispatch();

    const userInfo = useSelector(state => state.userInfo);
    const growBranches = useSelector(state => state.userInfo.grow_branches);


    useEffect(() => {
        const interval = setInterval(() => {
            console.log("growBranches.length", growBranches.length)
          if (growBranches.length!=0)
          dispatch(sProfile2());
        }, 60000);
        return () => clearInterval(interval);
      }, []);

    const wait = useSelector(state => state.friend.wait);
    const isMobile = useSelector(state => state.isMobile);
    const mClass = isMobile ? "-m" : "";

    useEffect(() => {
        if (isMobile) {
            window.scrollTo(0, 0);
        }
    }, [])

   
    const [findWinShow, setFindWinShow] = useState(false);

    function showFindWin() {
        dispatch(getOnlineUsers());
        setFindWinShow(true);
    }

    function hideFindWin() {
        setFindWinShow(false);
    }

    function playSingle() {
        // dispatch(setAppSection("game"));

        //играем с ботом

        //снимаем лист ожидания----------------
        //чистим лист приглашений--------------
        dispatch(battleInvitationOff());
        dispatch(unsetFriendForWait());

        dispatch(startWithPc());
    }

    function healthUpf() {
        dispatch(addBranch());
    }

    const findWin = (findWinShow) ? <FriendFinder exit={hideFindWin} /> : null;
    const waiterWin = (wait) ? <FriendWaiter /> : null;

    const btnCssDis = (growBranches.length == 3) ? " profile_btn_off" : "";

    const healthUp = (growBranches.length == 0 || userInfo.point<100) ? null : (
        <div className="profile_healthUp" onClick={healthUpf}>(<span className="profile_healthUp_u">добавить ветку за 100 баллов</span>)</div>
    );


    //////////////////////////////////////////

    let currView;
    if (!isMobile) {
        currView = (

            <>
                <ScaleDiv contetWidth="1503" contetHeight="1000" containerWidth={w} containerHeight={h}  >

                    <div className="profile-content-sep-0"></div>

                    <CanvasTree height={630} mode="profile" />

                    <div className="profile-content-sep-1"></div>

                    <div className="profile-content-txt">
                        <div className="profile_nickname">{userInfo.nick}</div>
                        <div className="profile_score">{userInfo.point + " " + Engine.num_word(userInfo.point, ["балл", "балла", "баллов"])}{healthUp}</div>
                        <div className="profile_level">{userInfo.level} уровень</div>
                    </div>
                    <div className="profile_btn_container">
                        <div className={"btn greenBtnIcon profile_btn" + btnCssDis} onClick={showFindWin}>
                            <div className="profile_btn_icon_1"></div>
                            <div>Выбрать соперника</div>
                        </div>

                        <div className="profile-content-sep-2"></div>

                        <div className={"btn greenBtnIcon profile_btn" + btnCssDis} onClick={playSingle}>
                            <div className="profile_btn_icon_2"></div>
                            <div>Играть с приложением</div>
                        </div>
                    </div>

                </ScaleDiv>
                {findWin}
                {waiterWin}
            </>

        );
    } else {
        currView = (

            <>
                <div className="profile-m">


                    <CanvasTree height={360} mode="profile" />

                    <div className="profile-content-txt">
                        <div className="profile_nickname-m">{userInfo.nick}</div>
                        <div className="profile_score-m">{userInfo.point + " " + Engine.num_word(userInfo.point, ["балл", "балла", "баллов"])}{<br />}{healthUp}</div>
                        <div className="profile_level-m">{userInfo.level} уровень</div>
                    </div>
                    <div className="profile_btn_container-m">
                        <div className={"btn greenBtnIcon profile_btn-m" + btnCssDis} onClick={showFindWin}>
                            <div className="profile_btn_icon_1"></div>
                            <div>Выбрать соперника</div>
                        </div>

                        <div className="profile-content-sep-2-m"></div>

                        <div className={"btn greenBtnIcon profile_btn-m" + btnCssDis} onClick={playSingle}>
                            <div className="profile_btn_icon_2"></div>
                            <div>Играть с приложением</div>
                        </div>
                    </div>
                </div>

                {findWin}
                {waiterWin}
            </>

        );
    }



    //////////////////////////////////////////

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }} className={"profile_container" + mClass} >

            {currView}
        </motion.div>
    )

}

export default Profile;