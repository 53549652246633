import "./Subscription.css";
import { useDispatch, useSelector } from 'react-redux';
import FContainer from "../../Base/FContainer/FContainer";
import GradientButton from "../../Base/GradientButton/GradientButton";
import { setAppSection } from '../../Gdata';
import { motion } from "framer-motion";

function Subscription() {

    const dispatch = useDispatch();

    const isMobile = useSelector(state => state.isMobile);
    const mClass = isMobile ? "mobile" : "pc";

    const goReg = () => {
        window.open("https://obr.nd.ru/order/bundle/254", "_self");
    }

    const mView = (
        <div id="subscription">
            <div className={"subscription-logo subscription-logo-" + mClass}></div>
            <div className={"subscription-img subscription-img-" + mClass}></div>
            <div className={"subscription-txt subscription-txt-" + mClass}>У вас отсутствует подписка или срок её действия истёк.</div>
            <GradientButton text="Купить" onClick={goReg}></GradientButton>
        </div>
    )

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }}
        >
            <FContainer>
                {mView}
            </FContainer>
        </motion.div>
    )
}

export default Subscription;