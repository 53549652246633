import Engine from "../../../services/Engine";
import "./ScaleDiv.css";

function ScaleDiv({ contetWidth, contetHeight, containerWidth, containerHeight, isScale, children }) {

    let scale = Engine.scaleCalc(Number(contetWidth), Number(contetHeight), Number(containerWidth), Number(containerHeight));

    if (isScale == "false") {
        return (
            <div className="ScaleDiv">
                <div className="ScaleDiv-content">
                    {children}
                </div>
            </div>
        )
    }

    return (
        <div className="ScaleDiv" style={{ transform: "scale(" + scale.scx + ")", width: scale.w, height: scale.h }}>
            <div className="ScaleDiv-content" style={{ width: contetWidth + "px", height: contetHeight + "px" }}>
                {children}
            </div>
        </div>
    )
}

export default ScaleDiv;
