import "./Registration.css";
import "../Intro/Intro.css";
import { useDispatch, useSelector } from 'react-redux';
import FContainer from "../../Base/FContainer/FContainer";
import GradientButtonMini from "../../Base/GradientButton/GradientButtonMini";
import { login, setAppSection, setIsLoading, registration, logOutReg } from '../../Gdata';
import { motion } from "framer-motion";
import { useState, useEffect } from 'react';

import Engine from '../../../services/Engine';


function Registration() {

    const dispatch = useDispatch();

    const isMobile = useSelector(state => state.isMobile);
    const isLoading = useSelector(state => state.xmlR.loading);
    const regError = useSelector(state => state.xmlR.regError);
    const regErrorNum = useSelector(state => state.xmlR.regErrorNum);
    const mClass = isMobile ? "mobile" : "pc";

    const [inputTxt1, setTxt1] = useState("");
    const [inputTxt2, setTxt2] = useState("");
    const [inputTxt3, setTxt3] = useState("");
    const [inputTxt4, setTxt4] = useState("");
    const [inputTxt5, setTxt5] = useState("");

    const [block, setBlock] = useState(true);

    const [section, setSection] = useState(1);
    const [style1, setStyle1] = useState("rbtn-active");
    const [style2, setStyle2] = useState("rbtn-passive");

    const [errTxtv, setErrTxtv] = useState("f");
    const [errTxt, setErrTxt] = useState("");

    let line0Width = (section == 2) ? "220px" : "0px";
    if (isMobile) {
        line0Width = (section == 2) ? "270px" : "0px";
    }

    const [introEnable, setIntroEnable] = useState("regEnabled");

    function go(par) {
        if (par === "all") {
            setSection(1)
        } else {
            setSection(2)
        }
    }

    useEffect(() => {
        dispatch(logOutReg());
    }, []);

    useEffect(() => {

        console.log(regError, "change!");

        if (regError != "") {
            setIntroEnable("regEnabled");
            setErrTxt(regError);
        }
    }, [regError, regErrorNum]);

    useEffect(() => {

        if (introEnable !== "regEnabled") {
            if (section == 1) {
                setStyle1("rrbtn-active");
                setStyle2("rrbtn-passivef");
            } else {
                setStyle2("rrbtn-active");
                setStyle1("rrbtn-passivef");
            }
        } else {
            if (section == 1) {
                setStyle1("rrbtn-active");
                setStyle2("rrbtn-passive");
            } else {
                setStyle2("rrbtn-active");
                setStyle1("rrbtn-passive");
            }
        }


    }, [section, introEnable]);

    useEffect(() => {

        if (inputTxt1 !== "" && inputTxt2 !== "" && inputTxt3 !== "" && inputTxt4 !== "" && inputTxt5 !== "" && inputTxt3.indexOf("@") !== -1 && inputTxt3.indexOf(".") !== -1 && isLoading === false) {
            setBlock(false);
        } else {
            setBlock(true);
        }
    }, [inputTxt1, inputTxt2, inputTxt3, inputTxt4, inputTxt5, isLoading]);


    useEffect(() => {
        console.log("errTxt", errTxt)
        if (errTxt !== "") {
            setErrTxtv("v");
        }
    }, [errTxt]);

    const goReg = (e) => {
        // console.log("goNickName")
        e.preventDefault();

        console.log(inputTxt1, inputTxt2, inputTxt3, inputTxt4, inputTxt5)

        if (!/^([а-яёa-z]+\s)([а-яёa-z]+\s?){1,2}$/im.test(inputTxt2)) {
            setErrTxt("Ошибка: Имя Фамилия не подходят.");
            return;
        }

        if (inputTxt4.length < 8 || inputTxt5.length < 8) {
            setErrTxt("Ошибка: Пароль должен содержать минимум 8 знаков.");
            return;
        }

        if (inputTxt4 != inputTxt5) {
            setErrTxt("Ошибка: Пароли не совпадают.");
            return;
        }


        setErrTxt("");
        setIntroEnable("regDisabled");

        const [last_name, first_name = '', patronymic = ''] = inputTxt2.split(' ');

        var o = {
            role: (section == 1) ? 'teacher' : 'student',
            nick: inputTxt1,
            last_name,
            first_name,
            patronymic,
            password1: inputTxt4,
            password2: inputTxt5,
            email: inputTxt3
        }

        console.log(o);

        dispatch(registration(o));
    }

    const goBack = (e) => {
        // console.log("goNickName")
        e.preventDefault();
        dispatch(setAppSection("login"));
    }


    const mView = (
        <div id="intro" className={introEnable}>

            <div className={"intro-logo intro-logo-" + mClass}></div>
            <div className="regTxt2">Регистрация</div>
            <div className={"rrating rrating-" + mClass}>
                <div className={"rrbtn " + style1} onClick={() => go("all")}>
                    Учитель
                </div>

                <div className="rrating-head-btns-gap">
                </div>

                <div className={"rrbtn " + style2} onClick={() => go("weak")}>
                    Ученик
                </div>
            </div>

            <div className="rrating-head-line">
                <div className="rrating-head-line-item0" style={{ width: line0Width }}></div>
                <div className="rrating-head-line-item"></div>
            </div>

            <div className="regTxt">Ник в игре</div>
            <div><input className={"reg-input-txt reg-input-txt-" + mClass} type="text" id="nick" name="nick" size="10" onChange={newValue => { setTxt1(newValue.target.value.trim()); }} /></div>
            <div className="regTxt">Фамилия Имя</div>
            <div><input className={"reg-input-txt reg-input-txt-" + mClass} type="text" id="name" name="name" onChange={newValue => { setTxt2(newValue.target.value.trim()); }} /></div>
            <div className="regTxt">Логин (e-mail)</div>
            <div><input className={"reg-input-txt reg-input-txt-" + mClass} type="text" id="login" name="login" onChange={newValue => { setTxt3(newValue.target.value.trim()); }} /></div>
            <div className="regTxt">Пароль</div>
            <div><input className={"reg-input-txt reg-input-txt-" + mClass} type="password" id="pass" name="pass" onChange={newValue => { setTxt4(newValue.target.value.trim()); }} /></div>
            <div className="regTxt">Подтверждение пароля</div>
            <div><input className={"reg-input-txt reg-input-txt-" + mClass} type="password" id="pass2" name="pass2" onChange={newValue => { setTxt5(newValue.target.value.trim()); }} /></div>

            <div className={"regTxt3 regTxt3v" + errTxtv}>{errTxt}</div>

            <div className="marginRegBtn">

                <GradientButtonMini text="Назад" onClick={goBack}></GradientButtonMini>
                <div className="rGap"></div>
                <GradientButtonMini block={block} text="Отправить" onClick={goReg}></GradientButtonMini>

            </div>


        </div>
    )

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }}
        >
            <FContainer>
                {mView}
            </FContainer>
        </motion.div>
    )
}

export default Registration;