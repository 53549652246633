import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useSelector } from 'react-redux';
import { setMainSection, setAppSection } from '../../Gdata';
import { useDispatch } from 'react-redux';
import "./Header.css";
import "../Button/Button.css";
import { useLayoutEffect, useRef, useState } from 'react';
import { resetTitleAnimation, setTitleAnimation0, setWSAction } from '../../Gdata';

function Header() {

    const dispatch = useDispatch();

    const isMobile = useSelector(state => state.isMobile);
    const mainSection = useSelector(state => state.mainSection);
    const treeLevel = useSelector(state => state.userInfo.level);
    const titleAnimation0 = useSelector(state => state.titleAnimation0);

    const navArr = ["p", "p", "p", "p", "p", "p", "p"];

    switch (mainSection) {
        default:
        case "profile":
            navArr[0] = "a";
            break;
        case "rating":
            navArr[1] = "a";
            break;
        case "achievements":
            navArr[2] = "a";
            break;
        case "store":
            navArr[3] = "a";
            break;
        case "tree":
            navArr[4] = "a";
            break;
        case "instr":
            //navArr[5] = "a";
            break;
    }

    function go(par) {

        dispatch(setWSAction({ com: "ping", payload: "" }));

        if (titleAnimation0 == "ready") {
            dispatch(setTitleAnimation0(null));
            dispatch(resetTitleAnimation());
        }

        if (par === "obr") {
            //window.open("https://obr.nd.ru/profile","_blank")
            dispatch(setAppSection("nickname"));
        }
        else if (par === "instr") {
            window.open('./instructions.pdf', '_blank');
        }
        else {
            dispatch(setMainSection(par));
        }
    }

    const mClass = (isMobile) ? "Header_mobile" : "Header_pc";

    /////////////////////////////////////////////////////////

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    ////////////////////////////////////////////////////////
    const ref = useRef(null);
    const [width, setWidth] = useState(0);
    useLayoutEffect(() => {
        setWidth(ref.current.clientWidth);
        console.log("width0", width);
    }, []);

    console.log("width", width);

    let storeCss = "";
    if (treeLevel < 6) {
        storeCss = " header_block";
    }

    return (
        <>
            <div id="header" className={mClass}>
                <div id="header_nav">

                    <div className="header_nav_mobile">
                        <div className="header_nav_mobile_btn btn" onClick={handleDrawerToggle}></div>
                    </div>

                    <div className="header_nav_pc" ref={ref}>

                        <div className="header_nav_gap"></div>

                        <div className="header_nav btn" onClick={() => go("profile")}>
                            <div className={"header_nav_icon nav nav1-" + navArr[0]}></div>
                            <div className={'header_nav_txt header_nav_txt-' + navArr[0]}>Главная</div>
                        </div>

                        <div className="header_nav btn" onClick={() => go("rating")}>
                            <div className={"header_nav_icon nav nav2-" + navArr[1]}></div>
                            <div className={'header_nav_txt header_nav_txt-' + navArr[1]}>Рейтинг игроков</div>
                        </div>

                        <div className="header_nav btn" onClick={() => go("achievements")}>
                            <div className={"header_nav_icon nav nav3-" + navArr[2]}></div>
                            <div className={'header_nav_txt header_nav_txt-' + navArr[2]}>Достижения</div>
                        </div>

                        <div className={"header_nav btn" + storeCss} onClick={() => go("store")}>
                            <div className={"header_nav_icon nav nav4-" + navArr[3]}></div>
                            <div className={'header_nav_txt header_nav_txt-' + navArr[3]}>Магазин</div>
                        </div>

                        <div className="header_nav btn" onClick={() => go("tree")}>
                            <div className={"header_nav_icon nav nav5-" + navArr[4]}></div>
                            <div className={'header_nav_txt header_nav_txt-' + navArr[4]}>Дерево</div>
                        </div>

                        <div className="header_nav btn" onClick={() => go("obr")}>
                            <div className={"header_nav_icon nav nav6-" + navArr[5]}></div>
                            <div className={'header_nav_txt header_nav_txt-' + navArr[5]}>Профиль игрока</div>
                        </div>

                        <div className="header_nav btn" onClick={() => go("instr")}>
                            <div className={"header_nav_icon nav nav7-" + navArr[6]}></div>
                            <div className={'header_nav_txt header_nav_txt-' + navArr[6]}>инструкция</div>
                        </div>

                        <div className="header_nav_gap"></div>

                    </div>


                </div>
                <div id="header_basement"></div>
            </div>

            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 430, backgroundColor: "#CCEA81" },
                    }}>
                    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'left', minHeight: "100vh" }}>

                        <div className='header_nav_mobile_menu_txt'>Меню</div>


                        <div className={"header_nav_mobile_menu_btn header_nav_mobile_menu_btn-" + navArr[0]} onClick={() => go("profile")}>
                            <div className="header_nav_icon nav nav1-a ml26"></div>
                            <div className='btn header_nav_txt header_nav_txt-a'>Главная</div>
                        </div>

                        <div className="header_nav_mobile_menu_btn_r"></div>

                        <div className={"header_nav_mobile_menu_btn header_nav_mobile_menu_btn-" + navArr[1]} onClick={() => go("rating")}>
                            <div className="header_nav_icon nav nav2-a ml26"></div>
                            <div className='btn header_nav_txt header_nav_txt-a'>Рейтинг игроков</div>
                        </div>

                        <div className="header_nav_mobile_menu_btn_r"></div>

                        <div className={"header_nav_mobile_menu_btn header_nav_mobile_menu_btn-" + navArr[2]} onClick={() => go("achievements")}>
                            <div className="header_nav_icon nav nav3-a ml26"></div>
                            <div className='btn header_nav_txt header_nav_txt-a'>Достижения</div>
                        </div>

                        <div className="header_nav_mobile_menu_btn_r"></div>

                        <div className={"header_nav_mobile_menu_btn header_nav_mobile_menu_btn-" + navArr[3] + storeCss} onClick={() => go("store")}>
                            <div className="header_nav_icon nav nav4-a ml26"></div>
                            <div className='btn header_nav_txt header_nav_txt-a'>Магазин</div>
                        </div>

                        <div className="header_nav_mobile_menu_btn_r"></div>

                        <div className={"header_nav_mobile_menu_btn header_nav_mobile_menu_btn-" + navArr[4]} onClick={() => go("tree")}>
                            <div className="header_nav_icon nav nav5-a ml26"></div>
                            <div className='btn header_nav_txt header_nav_txt-a'>Дерево</div>
                        </div>

                        <div className="header_nav_mobile_menu_btn_r"></div>

                        <div className={"header_nav_mobile_menu_btn header_nav_mobile_menu_btn-" + navArr[5]} onClick={() => go("obr")}>
                            <div className="header_nav_icon nav nav6-a ml26"></div>
                            <div className='btn header_nav_txt header_nav_txt-a'>Профиль игрока</div>
                        </div>

                        <div className="header_nav_mobile_menu_btn_r"></div>

                        <div className={"header_nav_mobile_menu_btn header_nav_mobile_menu_btn-" + navArr[6]} onClick={() => go("instr")}>
                            <div className="header_nav_icon nav nav7-a ml26"></div>
                            <div className='btn header_nav_txt header_nav_txt-a'>Инструкция</div>
                        </div>                       

                        <div className="header_nav_mobile_menu_btn_r"></div>
                    </Box>
                </Drawer>
            </Box>
        </>
    )
}

export default Header;