import "./Tree.css";
import CanvasTree from "../../Base/CanvasTree/CanvasTree";
import ScaleDiv from "../../Base/ScaleDiv/ScaleDiv";
import { useSelector } from 'react-redux';

function Tree({ w, h }) {

    const isMobile = useSelector(state => state.isMobile);

    let currView;
    if (!isMobile) {
        currView = (
            <ScaleDiv contetWidth="1100" contetHeight="800" containerWidth={w} containerHeight={h}  >
                <div className="tree-content">
                    <CanvasTree height={680} mode="tree" />
                </div>
            </ScaleDiv>
        )
    }
    else {
        currView = (
            <>
                <div className="tree-content-m">
                    <CanvasTree height={540} mode="tree" />
                </div>
            </>
        )
    }

    return (
        currView
    )
}

export default Tree;