import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Engine from "../services/Engine";
import Api from "../services/Api";

let url = Api.getUrl();
let urlr = Api.getUrl("r");


//логин по паролю
export const login = createAsyncThunk('gdata/login', async ({ email, password }) => {

    console.log(url, window.location.href)

    const data = new URLSearchParams();
    data.append('email', email);
    data.append('password', password);

    const settings = {
        method: 'POST',
        credentials: 'include',
        body: data
    };
    const fetchResponse = await fetch(url + 'auth/login', settings);
    return fetchResponse.json();
})

//логин по token
export const loginToken = createAsyncThunk('gdata/loginToken', async (token) => {

    const data = new URLSearchParams();
    data.append('token', token);

    const settings = {
        method: 'POST',
        credentials: 'include',
        body: data
    };
    const fetchResponse = await fetch(url + 'auth/login_token', settings);
    return fetchResponse.json();
})

//isЛогин по coockie
export const isLogin = createAsyncThunk('gdata/isLogin', async () => {

    console.log(url, window.location.href)

    const settings = {
        method: 'GET',
        credentials: 'include',
    };
    const fetchResponse = await fetch(url + 'auth/is_logged', settings);
    return fetchResponse.json();
})

//logOut
export const logOut = createAsyncThunk('gdata/logOut', async () => {
    const settings = {
        method: 'GET',
        credentials: 'include',
    };
    const fetchResponse = await fetch(url + 'auth/logout', settings);
    return fetchResponse.json();
})

//logOutR
export const logOutReg = createAsyncThunk('gdata/logReg', async () => {
    const settings = {
        method: 'POST',
        credentials: 'include',
    };
    const fetchResponse = await fetch(urlr + 'auth/logout', settings);
    return fetchResponse.json();
})

//getProfile
export const profile = createAsyncThunk('gdata/profile', async () => {
    const settings = {
        method: 'GET',
        credentials: 'include',
    };
    const fetchResponse = await fetch(url + 'user/profile', settings);
    return fetchResponse.json();
})

//никнейм
export const nickname = createAsyncThunk('gdata/nickname', async ({ nick }) => {

    const data = new URLSearchParams();
    data.append('nick', nick);

    const settings = {
        method: 'POST',
        credentials: 'include',
        body: data
    };
    const fetchResponse = await fetch(url + 'user/set_nick', settings);
    return fetchResponse.json();
})

//getUsers
export const getOnlineUsers = createAsyncThunk('gdata/getOnlineUsers', async () => {
    const settings = {
        method: 'GET',
        credentials: 'include',
    };
    const fetchResponse = await fetch(url + 'online_users/get', settings);
    return fetchResponse.json();
})

//конец игры
export const gameEnd = createAsyncThunk('gdata/gameEnd', async (game) => {

    console.log("end game", game);

    //const data = new URLSearchParams();
    // data.append('game', JSON.stringify(game));
    //data.append(JSON.stringify({ game: game }));

    const settings = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ game: game })
    };
    const fetchResponse = await fetch(url + 'game/end', settings);
    return fetchResponse.json();
})

//добавить ветку
export const addBranch = createAsyncThunk('gdata/addBranch', async () => {

    //const data = new URLSearchParams();
    //data.append('count', 1);
    // data.append(JSON.stringify({ count: 1 }));

    const settings = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ count: 1 })
    };
    const fetchResponse = await fetch(url + 'buy_branch/buy', settings);
    return fetchResponse.json();
})

//тестовые запросы
export const testReset = createAsyncThunk('gdata/testReset', async ({ user_id, action }) => {

    console.log(user_id, action);

    const data = new URLSearchParams();
    data.append('user_id', user_id);
    data.append('action', action);

    const settings = {
        method: 'POST',
        credentials: 'include',
        body: data
        //body: JSON.stringify({ user_id: uid, action: action })
    };
    const fetchResponse = await fetch(url + 'test_dev/reset', settings);
    return fetchResponse.json();
})

//STORE (goods)
export const storeGetAll = createAsyncThunk('gdata/storeGetAll', async () => {
    const settings = {
        method: 'GET',
        credentials: 'include',
    };
    const fetchResponse = await fetch(url + 'goods/get', settings);
    return fetchResponse.json();
})
export const storeGetOwn = createAsyncThunk('gdata/storeGetOwn', async () => {
    const settings = {
        method: 'GET',
        credentials: 'include',
    };
    const fetchResponse = await fetch(url + 'goods/own', settings);
    return fetchResponse.json();
})
export const storeBuy = createAsyncThunk('gdata/storeBuy', async ({ goods_id }) => {

    const data = new URLSearchParams();
    data.append('goods_id', goods_id);

    console.log(goods_id);

    const settings = {
        method: 'POST',
        credentials: 'include',
        body: data
    };
    const fetchResponse = await fetch(url + 'goods/buy', settings);
    return fetchResponse.json();
})
//Store Silent Profile
export const sProfile = createAsyncThunk('gdata/sprofile', async () => {
    const settings = {
        method: 'GET',
        credentials: 'include',
    };
    const fetchResponse = await fetch(url + 'user/profile', settings);
    return fetchResponse.json();
})
//Profile Health Silent update
export const sProfile2 = createAsyncThunk('gdata/sprofile2', async () => {
    const settings = {
        method: 'GET',
        credentials: 'include',
    };
    const fetchResponse = await fetch(url + 'user/profile', settings);
    return fetchResponse.json();
})
//Pc Game /game/start_with_computer
export const startWithPc = createAsyncThunk('gdata/startWithPc', async () => {
    const settings = {
        method: 'GET',
        credentials: 'include',
    };
    const fetchResponse = await fetch(url + 'game/start_with_computer', settings);
    return fetchResponse.json();
})
//Рейтинг весь
export const ratingFull = createAsyncThunk('gdata/ratingFull', async () => {
    const settings = {
        method: 'GET',
        credentials: 'include',
    };
    const fetchResponse = await fetch(url + 'rating/full', settings);
    return fetchResponse.json();
})
//Рейтинг неделя
export const ratingWeek = createAsyncThunk('gdata/ratingWeek', async () => {
    const settings = {
        method: 'GET',
        credentials: 'include',
    };
    const fetchResponse = await fetch(url + 'rating/week', settings);
    return fetchResponse.json();
})
//Ачивки
export const getAchieve = createAsyncThunk('gdata/getAchieve', async () => {
    const settings = {
        method: 'GET',
        credentials: 'include',
    };
    const fetchResponse = await fetch(url + 'achieve/get', settings);
    return fetchResponse.json();
})

//регистрация
//логин по паролю
export const registration = createAsyncThunk('gdata/registration', async (o) => {
    const settings = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(o)
    };
    const fetchResponse = await fetch(urlr + 'game/registration', settings);
    return fetchResponse.json();
})

//востановление пароля
export const passRec = createAsyncThunk('gdata/passRec', async (email) => {
    const settings = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({ "email": email })
    };
    const fetchResponse = await fetch(urlr + 'mail/forgot_password?gb=1', settings);
    return fetchResponse.json();
})

export const sendNewPass = createAsyncThunk('gdata/sendNewPass', async (o) => {


    const settings = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify(
            {
                code: o.code,
                password1: o.password1,
                password2: o.password2,
            }
        )
    };
    const fetchResponse = await fetch(urlr + 'user/' + o.id + '/password', settings);
    return fetchResponse.json();
})



//срез
const gdataSlice = createSlice({
    name: 'gdata',
    initialState: {
        isMobile: Engine.isMobile(),
        needUpdate: false,//?
        needUpdateProfile: 0,
        appSection: 'loading', //loading, intro, nickname, login, main,  game, registration, subscription, passwordrec, newpass
        mainSection: "profile",// profile, rating, achievements, store,tree, 
        version: "1.00",
        user: { //test
            nickname: "Nick Name",
            score: 0,
            level: 6,
            health: 3,
            fruit: 9000,
            money: 150,
            ///////////
        },
        userInfo: { //юсер от сервера
            id: 0,//1842554,
            nick: null,//null,
            level: 0,//1,
            point: 0,//0,
            fruit: 0,//0,            
            grow_branches: [],
            ws_token: ""//"350cc899099c536ba13c8e70418d3d984e6efc8d0546d165b8b66cdde9affb66"
        },
        addFruit: 0,
        friend: {
            wait: false,
            info: null,
            /*
         id: action.id,
         level: action.level,
         nick: action.nick,
         score: action.score
             */
        },
        store: {
            apples: [
                /*  { id: 0, name: "Кормушка", price: 200, pic: "feeder" },
                  { id: 1, name: "Скворечник", price: 300, pic: "birdhouse" },
                  { id: 2, name: "Гнездо", price: 400, pic: "nest" },
                  { id: 3, name: "Птичка", price: 500, pic: "birdie" },
                  { id: 4, name: "Кот ученый", price: 600, pic: "cat2" },
                  { id: 5, name: "Садовый фонарь", price: 700, pic: "lamp" },
                  { id: 6, name: "Садовый гном", price: 800, pic: "dwarf" },
                  { id: 7, name: "Садовые качели", price: 900, pic: "swing" },
                  { id: 8, name: "Садовый фонтан", price: 1000, pic: "fountain" },
                  { id: 9, name: "Беседка", price: 1100, pic: "alcove" },
                  { id: 10, name: "Клумба", price: 1200, pic: "flowerbed" },
                  */
            ],
            money: [],
            owned: [],
            update: 0
        },
        allPlayers: [
            /*  { userName: "Иван", level: "1", score: "1" },
              { userName: "Пётр", level: "2", score: "2" },
              { userName: "Евгений", level: "3", score: "3" },
              { userName: "Станислав", level: "4", score: "4" },
              { userName: "Вероника", level: "5", score: "5" },
              { userName: "Никита", level: "6", score: "6" },
              { userName: "Стас", level: "7", score: "7" },
              { userName: "Гена", level: "8", score: "8" },
              { userName: "Турбо", level: "9", score: "9" },
              { userName: "Дюша Метёлкин", level: "10", score: "10" },
              { userName: "Бендер", level: "81", score: "9999" },
              */
        ],
        rainting: {
            all: [
                { userName: "Иван", level: "6", score: "1000" },
                { userName: "Пётр", level: "5", score: "200" },
                { userName: "Евгений", level: "4", score: "30" },
                { userName: "Станислав", level: "3", score: "4" },
                { userName: "Вероника", level: "2", score: "3" },
                { userName: "Никита", level: "1", score: "2" },
                { userName: "Иван", level: "6", score: "1000" },
                { userName: "Пётр", level: "5", score: "200" },
                { userName: "Евгений", level: "4", score: "30" },
                { userName: "Станислав", level: "3", score: "4" },
            ],
            week: [
                { userName: "Стас", level: "6", score: "1000" },
                { userName: "Гена", level: "5", score: "80" },
                { userName: "Турбо", level: "4", score: "50" },
                { userName: "Дюша Метёлкин", level: "3", score: "20" },
                { userName: "Бендер", level: "2", score: "15" },

                { userName: "Стас", level: "6", score: "5" },
                { userName: "Гена", level: "5", score: "4" },
                { userName: "Турбо", level: "4", score: "3" },
                { userName: "Дюша Метёлкин", level: "2", score: "1" },
                { userName: "Бендер", level: "2", score: "0" },
            ]
        },
        xmlR: {
            phase: 0,// 0 старт, 1 грузим, профиль 2 профиль загружен мы в меню 
            phase2: 0,
            loading: false,
            error: "",
            regError: "",
            regErrorNum: 0,
            prError: "",
            prNum: 0,
        },
        pmData: "",
        pmTreeData: "",
        ///////////////////////
        titleAnimation: null,
        titleAnimation0: null,
        webSocket: {
            i: 0,
            com: null,
            payload: null
        },
        battleInvitation: [
            /*  {
              user: null,
              show: false,
              }
              */
        ],
        game: {
            inGame: false,
            mode: null,//pc,player
            myId: null,
            myPNum: -1,
            player1: null,
            player2: null,
            p1gameReady: false,
            p2gameReady: false,
            bothStart: false,
            questions: null,
        },
        amIfirstPlayer: null,
        acceptInvite: false,
        //WS---переменные-------------------------
        wsCanvasData: 0,
        wsDataMSG: null,
        wsTimeToSendReady: 0,
        wsStatus: "",
        wsIsSetUp: false,
        achieve: [],
        maxAchieve: 110,
        //-------------------
        newPass: {
            user_id: "",
            code: ""
        },
        newPassError: "",
    },
    reducers: {
        setMobile: state => { state.isMobile = true },
        setAppSection: (state, action) => { state.appSection = action.payload },
        setHomeSection: state => { state.appSection = "main"; state.mainSection = "profile"; },
        setMainSection: (state, action) => { state.mainSection = action.payload },
        setUserNick: (state, action) => { state.user.nickname = action.payload },
        setFriendForWait: (state, action) => {
            state.friend.wait = true;

            state.friend.info = {
                id: action.payload.id,
                level: action.payload.level,
                nick: action.payload.nick,
                score: action.payload.score
            };
        },
        unsetFriendForWait: state => { state.friend.wait = false; state.friend.info = null; },
        setIsLoading: (state, action) => { state.xmlR.loading = action.payload },
        buyItemApples: (state, action) => {
            state.userInfo.fruit -= action.payload.price;
            state.store.owned.push(action.payload.obj);
        },
        setError: (state, action) => { state.xmlR.error = action.payload },
        setPMData: (state, action) => { state.pmData = action.payload },
        setPMTreeData: (state, action) => { state.pmTreeData = action.payload },
        /////////////////////////////////////////////////////////////////////////
        levelUp: state => { if (state.userInfo.level < 6) { state.userInfo.level++; state.titleAnimation = "levelUp"; } },
        levelDown: state => { if (state.userInfo.level > 1) { state.userInfo.level--; state.titleAnimation = "levelDown"; } },
        addHealth: state => {
            if (state.user.health < 3) { state.user.health++; /* state.titleAnimation = "addHealth";*/ }
        },
        minusHealth: state => {
            if (state.user.health > 0) { state.user.health--; /*state.titleAnimation = "minusHealth";*/ }
        },
        addApples: (state, action) => {
            state.titleAnimation = "addApples";
            state.userInfo.fruit += action.payload;
            state.addFruit = action.payload;
        },
        resetTitleAnimation: state => { state.titleAnimation = null; },
        setTitleAnimation0: (state, action) => { state.titleAnimation0 = action.payload; },
        setWSAction: (state, action) => { state.webSocket.com = action.payload.com; state.webSocket.payload = action.payload.payload; state.webSocket.i++; },
        setBattleInvitation: (state, action) => { state.battleInvitation.push(action.payload); },
        reject_battleInvitation: state => { state.battleInvitation.shift(); },
        reject_battleInvitationId: (state, action) => {
            for (var i = state.battleInvitation.length - 1; i >= 0; i--) {
                console.log(state.battleInvitation[i].user, action.payload);
                if (state.battleInvitation[i].id == action.payload) {
                    state.battleInvitation.splice(i, 1);
                }
            }
        },
        battleInvitationOff: state => { state.battleInvitation = []; },
        setGame: (state, action) => { state.game = action.payload },
        exitGame: state => {
            state.game.inGame = false;
            state.game.mode = null;
            state.game.myId = null;
            state.game.myPNum = -1;
            state.game.player1 = null;
            state.game.player2 = null;
            state.game.p1gameReady = false;
            state.game.p2gameReady = false;
            state.game.bothStart = false;
            state.game.questions = null;
        },
        setP1gameReady: state => { state.game.p1gameReady = true; },
        setP2gameReady: state => { state.game.p2gameReady = true; },
        setBothStart: state => { state.game.bothStart = true; },
        setAmIFirstPlayer: (state, action) => { state.amIfirstPlayer = action.payload; console.log("set state.amIfirstPlayer") },
        setwsDataMSG: (state, action) => { state.wsDataMSG = action.payload; state.wsCanvasData++; },
        setwsTimeToSendReady: state => { console.log("!CCC! setwsTimeToSendReady"); state.wsTimeToSendReady++; },
        setWsStatus: (state, action) => { state.wsStatus = action.payload; },
        setWsIsSetUp: (state, action) => { state.wsIsSetUp = action.payload; },
        ////////////////////////////
        setStartBotGame: state => {
        },
        setAcceptInvite: (state, action) => { state.acceptInvite = action.payload; },
        setUpdateProfile: state => { state.needUpdateProfile++; },
        setprNum: (state, action) => { state.xmlR.prNum = action.payload; },
        setNewPass: (state, action) => {
            state.newPass.user_id = action.payload.user_id;
            state.newPass.code = action.payload.code;
        },
        resetNPError: state => { state.newPassError = "" }
},
extraReducers(builder) {
    builder
        //Логин по паролю
        .addCase(login.pending, (state, action) => {
            console.log("отправка логина");
            state.xmlR.loading = true;
        })
        .addCase(login.fulfilled, (state, action) => {
            console.log("получили логин", action.payload);

            if (action.payload.length == 0) {
                //window.location.reload(false);
                console.log("RESET 1");
            }

            if (action.payload.error != undefined) {

                if (action.payload.error.message == "Пользователь уже выполнил вход") {
                    window.location = window.location.href.split("?")[0];
                }

                state.xmlR.error = "Ошибка входа | " + action.payload.error.message;
                state.xmlR.loading = false;
            } else {
                state.xmlR.phase = 1;
            }
        })
        .addCase(login.rejected, (state, action) => {
            state.xmlR.error = "Ошибка входа | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
            console.log("error", action.error);
        })
        //Логин по token
        .addCase(loginToken.pending, (state, action) => {
            console.log("отправка логина");
            state.xmlR.loading = true;
        })
        .addCase(loginToken.fulfilled, (state, action) => {
            console.log("получили loginToken", action.payload);

            console.log("XXX", action.payload);


            if (action.payload.length == 0) {
                //window.location.reload(false);
                //   setTimeout(function(){
                window.location = window.location.origin;
                //   }, 20000)

                console.log("RESET 1");
            }

            if (action.payload.error != undefined) {

                console.log("RESET 2");

                // setTimeout(function(){
                window.location = window.location.origin;
                // }, 20000)
                //console.log(queryParameters);


                state.xmlR.error = "Ошибка входа | " + action.payload.error.message;
                state.xmlR.loading = false;
            } else {
                state.xmlR.phase = 1;
            }
        })
        .addCase(loginToken.rejected, (state, action) => {
            state.xmlR.error = "Ошибка входа | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
            console.log("error", action.error);
        })
        //Установить никнейм
        .addCase(nickname.pending, (state, action) => {
            console.log("отправка ника");
            state.xmlR.loading = true;
        })
        .addCase(nickname.fulfilled, (state, action) => {
            console.log("получили ник", action.payload);
            if (action.payload.error != undefined) {
                state.xmlR.error = "Ошибка изменения ника | " + action.payload.error.message + ". " + action.payload.error.detail.nick[0];
            } else {
                // setAppSection("main");
                state.mainSection = "profile";
                state.xmlR.phase = 1;
            }
            state.xmlR.loading = false;
        })
        .addCase(nickname.rejected, (state, action) => {
            console.log("error ник", action.error);
            state.xmlR.error = "Ошибка раздела Никнейм| Не удалось подключиться к серверу";
        })
        //проверка кук и Логина
        .addCase(isLogin.pending, (state, action) => {
            console.log("проверка на наличия кук");
        })
        .addCase(isLogin.fulfilled, (state, action) => {
            console.log("пользователь авторизован", action.payload);
            if (action.payload.is_logged == 1) {
                console.log("залогин");
                state.xmlR.phase = 1;
            } else {
                console.log("не залогин 1");
                state.appSection = "intro";
                //state.appSection = "subscription";
            }
            state.xmlR.loading = false;
        })
        .addCase(isLogin.rejected, (state, action) => {
            console.log("не залогин 2");
            state.xmlR.loading = false;
            state.appSection = "intro";
        })
        //получаем профиль
        .addCase(profile.pending, (state, action) => {
            console.log("получаем профиль");
            state.xmlR.loading = true;
        })
        .addCase(profile.fulfilled, (state, action) => {
            console.log("профиль", action.payload);
            state.xmlR.loading = false;

            if (action.payload.error != undefined) {
                console.log("профиль ошибка", action.payload);
                //window.location.reload(false);
                state.xmlR.error = action.payload.error.detail + " | " + action.payload.error.message;
            } else {

                //Exit game//////////////
                state.game.inGame = false;
                state.game.mode = null;
                state.game.myId = null;
                state.game.myPNum = -1;
                state.game.player1 = null;
                state.game.player2 = null;
                state.game.p1gameReady = false;
                state.game.p2gameReady = false;
                state.game.bothStart = false;
                state.game.questions = null;

                state.acceptInvite = false;

                state.battleInvitation = [];
                state.friend.wait = false;
                state.friend.info = null;
                ////////////////////////////

                //анимации!!!!!!!!!!!!!!!!!!
                let double = 0;

                if (state.xmlR.phase2 == 2) {
                    if (state.userInfo.level == action.payload.user.level - 1) {
                        state.titleAnimation = "levelUp";
                        double++;
                    }
                    if (state.userInfo.level == action.payload.user.level + 1) {
                        state.titleAnimation = "levelDown";
                    }

                    console.log("Gфрукты ", state.userInfo.fruit, action.payload.user.fruit);
                    if (state.userInfo.fruit < action.payload.user.fruit) {
                        state.titleAnimation = "addApples";
                        double++;
                        state.addFruit = action.payload.user.fruit - state.userInfo.fruit;
                    }
                }

                if (double == 2) {
                    state.titleAnimation = "levelUp";
                }

                /////////////////////////////

                state.userInfo = action.payload.user;

                if (state.xmlR.phase2 == 0) {
                    state.xmlR.phase = 2;
                    state.xmlR.phase2 = 2;
                }
                if (action.payload.user.nick == null) {
                    state.appSection = "nickname";
                } else {
                    state.appSection = "main";
                }
            }
        })
        .addCase(profile.rejected, (state, action) => {
            console.log("профиль ошибка");
            state.xmlR.loading = false;
        })
        //выйти
        .addCase(logOut.pending, (state, action) => {
            console.log("выйти отправка");
        })
        .addCase(logOut.fulfilled, (state, action) => {
            console.log("вышел", action.payload);
            state.xmlR.loading = false;
            state.appSection = "intro";
        })
        .addCase(logOut.rejected, (state, action) => {
            console.log("вышел ошибка", action.payload);
        })
        //выйтиReg
        .addCase(logOutReg.pending, (state, action) => {
            console.log("logOutReg отправка");
        })
        .addCase(logOutReg.fulfilled, (state, action) => {

            console.log("!!!!!logOutReg!!!!!!!!!!!")

            console.log("logOutReg", action.payload);
        })
        .addCase(logOutReg.rejected, (state, action) => {
            console.log("logOutReg ошибка", action.payload);
        })
        //онлайе юзеры
        .addCase(getOnlineUsers.pending, (state, action) => {
            console.log("получаем онлайн юзеров");
        })
        .addCase(getOnlineUsers.fulfilled, (state, action) => {
            console.log("получили онлайн юзеров", action.payload);

            if (action.payload.error != undefined) {
                console.log("получаем онлайн юзеров ошибка", action.payload);
            }
            else {
                state.allPlayers = action.payload.users.data;
            }
            state.xmlR.loading = false;
            //state.appSection = "intro";
        })
        .addCase(getOnlineUsers.rejected, (state, action) => {
            console.log("получаем онлайн юзеров ошибка", action.payload);
        })
        //game end
        .addCase(gameEnd.pending, (state, action) => {
            console.log("отправка данных об окончании игры");
        })
        .addCase(gameEnd.fulfilled, (state, action) => {
            console.log("получили данные об окончании игры", action.payload);
        })
        .addCase(gameEnd.rejected, (state, action) => {
            state.xmlR.error = "Ошибка отправки данных об окончании игры | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
            console.log("error", action.error);
            console.log("RESET 2");
            window.location.reload(false);
        })
        //test
        .addCase(testReset.pending, (state, action) => {
            console.log("отправка тестовых данных");
        })
        .addCase(testReset.fulfilled, (state, action) => {
            console.log("получили тестовых данные", action.payload);
        })
        .addCase(testReset.rejected, (state, action) => {
            state.xmlR.error = "Ошибка отправки тестовых данных | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
            console.log("error", action.error);
        })
        //STORE 
        .addCase(storeGetAll.pending, (state, action) => {
            console.log("отправка данных storeGetAll");
        })
        .addCase(storeGetAll.fulfilled, (state, action) => {
            console.log("получили данные storeGetAll", action.payload);

            let aArr = []
            for (let i = 0; i < action.payload.goods.length; i++) {
                const el = action.payload.goods[i];
                aArr.push({
                    id: i,
                    idx: el.id,
                    name: el.name,
                    price: el.price,
                });
            }
            state.store.apples = aArr;
        })
        .addCase(storeGetAll.rejected, (state, action) => {
            state.xmlR.error = "Ошибка отправки данных магазина | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
            console.log("error", action.error);
        })
        //
        .addCase(storeGetOwn.pending, (state, action) => {
            console.log("отправка данных storeGetOwn");
        })
        .addCase(storeGetOwn.fulfilled, (state, action) => {
            console.log("получили данные storeGetOwn", action.payload);

            let aArr = []
            for (let i = 0; i < action.payload.buy_goods.length; i++) {
                const el = action.payload.buy_goods[i].goods;
                aArr.push({
                    idx: el.id,
                    name: el.name,
                    price: el.price,
                });
            }
            state.store.owned = aArr;

        })
        .addCase(storeGetOwn.rejected, (state, action) => {
            state.xmlR.error = "Ошибка отправки данных магазина | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
            console.log("error", action.error);
        })
        //
        .addCase(storeBuy.pending, (state, action) => {
            console.log("отправка данных storeBuy");
        })
        .addCase(storeBuy.fulfilled, (state, action) => {
            console.log("получили данные storeBuy", action.payload);
            state.store.update++;
        })
        .addCase(storeBuy.rejected, (state, action) => {
            state.xmlR.error = "Ошибка отправки данных магазина | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
            console.log("error", action.error);
        })
        .addCase(sProfile.pending, (state, action) => {
            console.log("получаем профиль");
            state.xmlR.loading = true;
        })
        .addCase(sProfile.fulfilled, (state, action) => {
            console.log("профиль", action.payload);
            state.xmlR.loading = false;
            if (action.payload.error != undefined) {
                console.log("профиль ошибка", action.payload);
            } else {
                state.userInfo = action.payload.user;
            }
        })
        .addCase(sProfile.rejected, (state, action) => {
            console.log("профиль ошибка");
            state.xmlR.loading = false;
        })
        .addCase(sProfile2.pending, (state, action) => {
            console.log("получаем профиль 2S");
            state.xmlR.loading = true;
        })
        .addCase(sProfile2.fulfilled, (state, action) => {
            console.log("профиль 2S", action.payload);
            state.xmlR.loading = false;
            if (action.payload.error != undefined) {
                console.log("профиль ошибка", action.payload);
            } else {
                if (state.userInfo.grow_branches.length != action.payload.user.grow_branches.length) {
                    state.userInfo.grow_branches = action.payload.user.grow_branches;
                }
            }
        })
        .addCase(sProfile2.rejected, (state, action) => {
            console.log("профиль профиль 2S ошибка");
            state.xmlR.loading = false;
        })
        ///////////////////////////////////////////////////////
        .addCase(startWithPc.pending, (state, action) => {
            console.log("получаем startWithPc");
            state.xmlR.loading = true;
        })
        .addCase(startWithPc.fulfilled, (state, action) => {
            console.log("startWithPc", action.payload);

            state.wsDataMSG = action.payload;
            state.wsCanvasData++;

        })
        .addCase(startWithPc.rejected, (state, action) => {
            console.log("startWithPc ошибка");
            state.xmlR.loading = false;
        })
        //RATING/////////////////////////////////////////////////////
        .addCase(ratingFull.pending, (state, action) => {
            console.log("отправка данных ratingFull");
        })
        .addCase(ratingFull.fulfilled, (state, action) => {
            console.log("получили данные ratingFull", action.payload);
            state.rainting.all = action.payload.rating.data;

        })
        .addCase(ratingFull.rejected, (state, action) => {
            state.xmlR.error = "Ошибка отправки данных рейтинга | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
            console.log("error", action.error);
        })
        //Week
        .addCase(ratingWeek.pending, (state, action) => {
            console.log("отправка данных ratingWeek");
        })
        .addCase(ratingWeek.fulfilled, (state, action) => {
            console.log("получили данные ratingWeek", action.payload);
            state.rainting.week = action.payload.rating.data;
        })
        .addCase(ratingWeek.rejected, (state, action) => {
            state.xmlR.error = "Ошибка отправки данных рейтинга | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
            console.log("error", action.error);
        })
        //getAchieve
        .addCase(getAchieve.pending, (state, action) => {
            console.log("отправка данных getAchieve");
        })
        .addCase(getAchieve.fulfilled, (state, action) => {
            console.log("получили данные getAchieve", action.payload);
            state.achieve = action.payload.achives;
            state.maxAchieve = action.payload.max_achives;
        })
        .addCase(getAchieve.rejected, (state, action) => {
            state.xmlR.error = "Ошибка отправки данных ачивок | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
            console.log("error", action.error);
        })
        //addBranch
        .addCase(addBranch.pending, (state, action) => {
            console.log("отправка данных addBranch");
        })
        .addCase(addBranch.fulfilled, (state, action) => {
            console.log("получили данные addBranch", action.payload);
            state.achieve = action.payload.achives;
            state.needUpdateProfile++;
        })
        .addCase(addBranch.rejected, (state, action) => {
            state.xmlR.error = "Ошибка отправки данных addBranch | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
            console.log("error", action.error);
            state.needUpdateProfile++;
        })
        //regestration
        .addCase(registration.pending, (state, action) => {
            console.log("отправка данных regestration");
        })
        .addCase(registration.fulfilled, (state, action) => {
            console.log("получили данные regestration", action.payload);

            if (action.payload.error != undefined) {

                let dop = "";
                if (action.payload.field != undefined) {
                    if (action.payload.field.email != undefined) {
                        if (action.payload.field.email[0] == "The email has already been taken.") {
                            dop = ". Данный Email уже занят."
                        }
                    }
                }
                state.xmlR.regError = action.payload.error + dop;
                state.xmlR.regErrorNum++;
            }


            if (action.payload.token != undefined) {
                window.location.href = window.location.href + '?token=' + action.payload.token;
            }
        })
        .addCase(registration.rejected, (state, action) => {
            state.xmlR.error = "Ошибка отправки данных regestration | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
        })
        //passrec
        .addCase(passRec.pending, (state, action) => {
            console.log("отправка данных восстановление пароля");
        })
        .addCase(passRec.fulfilled, (state, action) => {
            console.log("получили данные восстановление пароля", action.payload);

            if (action.payload.done == false) {
                state.xmlR.prError = action.payload.error;
            } else {
                state.xmlR.prError = "";
            }
            state.xmlR.prNum++;


        })
        .addCase(passRec.rejected, (state, action) => {
            state.xmlR.error = "Ошибка восстановление пароля | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
        })
        //sendNewPass
        .addCase(sendNewPass.pending, (state, action) => {
            state.newPassError = "";
            console.log("отправка данных сохранения пароля");
        })
        .addCase(sendNewPass.fulfilled, (state, action) => {
            console.log("получили данные сохранения пароля", action.payload);

            //{"done":true}
            if (action.payload.done == true) {

                state.newPassError = "done";

            } else {
                state.newPassError = action.payload.error;
            }
        })
        .addCase(sendNewPass.rejected, (state, action) => {
            state.xmlR.error = "Ошибка сохранения пароля | Не удалось подключиться к серверу";
            state.xmlR.loading = false;
        })
}
})

const { actions, reducer } = gdataSlice;
export default reducer;
export const {
    setUpdateProfile, setprNum, setNewPass, resetNPError,
    setAcceptInvite, setwsDataMSG, setwsTimeToSendReady, setWsStatus, setWsIsSetUp, setStartBotGame,
    battleInvitationOff, setGame, setP1gameReady, setP2gameReady, setBothStart, setAmIFirstPlayer,
    reject_battleInvitationId, reject_battleInvitation, levelUp, levelDown, addHealth, minusHealth, addApples, resetTitleAnimation, setTitleAnimation0,
    setMobile, setPMData, setPMTreeData, setError, setHomeSection, setAppSection,
    setUserNick, setMainSection, setFriendForWait, unsetFriendForWait, buyItemApples,
    buyItemMoney, setIsLoading, setWSAction, setBattleInvitation
} = actions;