import "./Intro.css";
import { useDispatch, useSelector } from 'react-redux';
import FContainer from "../../Base/FContainer/FContainer";
import GradientButton from "../../Base/GradientButton/GradientButton";
import { setAppSection } from '../../Gdata';
import { motion } from "framer-motion";

function Intro() {

    const dispatch = useDispatch();

    const isMobile = useSelector(state => state.isMobile);
    const mClass = isMobile ? "mobile" : "pc";

    const goReg = () => {
       /*
        if (window.location.href.indexOf("obrdev") !== -1 || window.location.href.indexOf("localhost") !== -1) {
            window.open("https://ltc-english.obrdev.ru/gardenbattle/registration", "_self");
        }else{
            window.open("https://lms.ltc-english.ru/gardenbattle/registration", "_self"); 
        }   
        */
        dispatch(setAppSection("registration"));    
    }

    const goLogin = () => {
        console.log("goNickName")
        dispatch(setAppSection("login"));
    }

    const mView = (
        <div id="intro">
            <div className={"intro-logo intro-logo-" + mClass}></div>
            <div className={"intro-img intro-img-" + mClass}></div>
            <div className="marginBtn">
                <GradientButton text="Войти" onClick={goLogin}></GradientButton>
            </div>
            <GradientButton text="Зарегистрироваться" onClick={goReg}></GradientButton>
        </div>
    )

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }}
        >
            <FContainer>
                {mView}
            </FContainer>
        </motion.div>
    )
}

export default Intro;