import "./PasswordRec.css";
import "../Intro/Intro.css";
import "../Registration/Registration.css";
import { useDispatch, useSelector } from 'react-redux';
import FContainer from "../../Base/FContainer/FContainer";
import GradientButtonMini from "../../Base/GradientButton/GradientButtonMini";
import { setAppSection, passRec, setprNum } from '../../Gdata';
import { motion } from "framer-motion";
import { useState, useEffect } from 'react';

import Engine from '../../../services/Engine';


function PasswordRec() {

    const dispatch = useDispatch();

    const isMobile = useSelector(state => state.isMobile);

    const prError = useSelector(state => state.xmlR.prError);
    const prNum = useSelector(state => state.xmlR.prNum);

    const mClass = isMobile ? "mobile" : "pc";

    const [inputTxt1, setTxt1] = useState("");
    const [block, setBlock] = useState(true);

    const [pop1, setPop1] = useState("prPopVT");
    const [pop2, setPop2] = useState("prPopVF");

    const [ptxt1, setPTxt1] = useState("Запрос на восстановление пароля прошел успешно!");
    const [ptxt2, setPTxt2] = useState("На указанную электронную почту отправлено письмо с дальнейшими инструкциями");

    useEffect(() => {
        if (inputTxt1.indexOf("@") !== -1 && inputTxt1.indexOf(".") !== -1) {
            setBlock(false);
        } else {
            setBlock(true);
        }
    }, [inputTxt1]);

    useEffect(() => {

        if (prNum > 0) {
            if (prError == "") {
                setPTxt1("Запрос на восстановление пароля прошел успешно!");
                setPTxt2("На указанную электронную почту отправлено письмо с дальнейшими инструкциями");
            } else {
                setPTxt1("Ошибка!");
                setPTxt2(prError);

            }
            setPop2("prPopVT");
        } else{
            setPop2("prPopVF");
        }
    }, [prNum]);


    const goRec = (e) => {
        // console.log("goNickName")
        e.preventDefault();

        console.log(inputTxt1)

        setPop1("prPopVF");
        //setPop2("prPopVT");

        dispatch(passRec(inputTxt1))
    }

    const goBack = (e) => {
        // console.log("goNickName")
        e.preventDefault();
        dispatch(setprNum(0));
        dispatch(setAppSection("login"));
    }


    const mView = (
        <div id="intro">

            <div className={"intro-logo intro-logo-" + mClass}></div>

            <div className={"prTxt2-" + mClass}>Восстановление пароля</div>

            <div className={pop1}>

                <div className="regTxt">Электронная почта</div>
                <div><input className={"reg-input-txt reg-input-txt-" + mClass} type="text" id="nick" name="nick" size="10" onChange={newValue => { setTxt1(newValue.target.value.trim()); }} /></div>

                <div className={"marginPrBtn-" + mClass}>

                    <GradientButtonMini text="Назад" onClick={goBack}></GradientButtonMini>
                    <div className={"pGap-" + mClass}></div>
                    <GradientButtonMini block={block} text="Восстановить пароль" onClick={goRec}></GradientButtonMini>

                </div>

            </div>

            <div className={pop2}>

                <div className="prPop">

                    <div className="prPop-txt1">
                        {ptxt1}
                    </div>

                    <div className="prPop-txt2">
                    {ptxt2}
                    </div>

                    <GradientButtonMini text="Назад" onClick={goBack}></GradientButtonMini>

                </div>


            </div>
        </div>
    )

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }}
        >
            <FContainer>
                {mView}
            </FContainer>
        </motion.div>
    )
}

export default PasswordRec;