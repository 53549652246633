import "./GradientButton.css";
import "../Button/Button.css";
import { useSelector } from 'react-redux';

function  GradientButtonMini ({text, fontSize, block, padding, ...buttonProps}) {    
    
    
    const isMobile = useSelector(state => state.isMobile);
    const mClass = isMobile ? "gradient-button-mini-mobile" : "gradient-button-mini-pc";

    const blockClass = block ? " block" : "";
  
    const fSize = isMobile ? (Number(fontSize)-8)+"pt": fontSize+"pt";

    let paddingStr = padding ? "10px "+ padding+"px" : "10px 30px";
    if (isMobile) {
        paddingStr = "20px 50px";
    }

    return (
        <div className={"btn gradient-button-mini " + mClass+blockClass} {...buttonProps} style={{fontSize: fSize, padding: paddingStr}}>
            {text}
        </div>
    )
}
export default GradientButtonMini;