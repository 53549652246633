import './App.css';
import { Main, Loading, NewPass, Intro, Nickname, Game, PasswordRec, Login, Registration, Subscription } from './components/Pages/index';
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef } from 'react';
import { storeGetOwn, profile, setUpdateProfile, setNewPass, sProfile, isLogin, loginToken, setError, setPMData, setPMTreeData, resetTitleAnimation, setTitleAnimation0, setAcceptInvite, setWSAction, setAppSection, setP1gameReady, setP2gameReady, setBothStart, setAmIFirstPlayer, gameEnd } from './components/Gdata';
import { useDispatch, useSelector } from 'react-redux';
import WebSocketContainer from './components/Base/WebSocket/WebSocket';

import * as React from 'react';
import Dialog from '@mui/material/Dialog';

import GradientButtonMini from "./components/Base/GradientButton/GradientButtonMini";

function App() {

  const refx = useRef(null);

  const appSection = useSelector(state => state.appSection);
  const isMobile = useSelector(state => state.isMobile);
  const phase = useSelector(state => state.xmlR.phase);
  const errorMSG = useSelector(state => state.xmlR.error);
  const pmData = useSelector(state => state.pmData);
  const battleInvitation = useSelector(state => state.battleInvitation);
  const gameData = useSelector(state => state.game);
  const needUpdateP = useSelector(state => state.needUpdateProfile);

  const [wsReady, setWsReady] = React.useState(false);

  const dispatch = useDispatch();

  //стили
  const mClass = (isMobile) ? "mobile" : "pc";

  useEffect(() => {
    dispatch(sProfile());
  }, [needUpdateP])

  useEffect(() => {


    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("token");
    console.log("token=" + token);
    console.log("restore=", queryParameters.get("redirect"));
    console.log("password-recovery", queryParameters.get("password-recovery"));

    if (token != null) {
      dispatch(loginToken(token));
    } else {
      //?redirect=login
      if (queryParameters.get("redirect") == "login") {
        dispatch(setAppSection("login"));
      } 
      else if (queryParameters.get("password-recovery") != null && queryParameters.get("code")!= null) {
        dispatch(setNewPass({user_id: queryParameters.get("password-recovery"), code: queryParameters.get("code")}));
        dispatch(setAppSection("newpass"));
      }
      else {
        dispatch(isLogin());
      }
    }

    //игра//-------------------
    if (window.addEventListener) {
      window.addEventListener("message", listener);
    } else {
      // IE8
      window.attachEvent("onmessage", listener);
    }
  }, [])

  function listener(event) {

    if (event.data === "ready") {
      dispatch(setPMData("ready"));
    }

    if (event.data === "canvas tree is ready") {
      dispatch(setPMTreeData("treeStart" + getRandom(0, 9999)));
    }

    if (event.data === "need update") {
      dispatch(setUpdateProfile());
    }

    if (event.data === "end tree animation") {
      dispatch(setTitleAnimation0("ready"));
    }

    if (event.data === "pingus") {
      dispatch(setWSAction({ com: "ping", payload: "" }));
    }

    if (event.data === "fullCloseGame") {
      dispatch(setPMData(""));
      dispatch(profile());
    }

    if (event.data === "otherPlayerDead") {
      dispatch(setPMData(""));
      dispatch(profile());
      dispatch(setError("Потеряна связь со вторым игроком! | Вам будет засчитана победа."));
    }

    let oArr;
    if (String(event.data).indexOf("open_question") != -1) {
      oArr = String(event.data).split("|");
      dispatch(setWSAction({
        com: "open_question", payload: {
          cell_number: oArr[1],
          user_id: oArr[2],
        }
      }));
    }

    if (String(event.data).indexOf("custom_com") == 0) {
      oArr = String(event.data).split("|");

      dispatch(setWSAction({
        com: "custom_com",
        payload: {
          user_id: oArr[1],
          payload: oArr[2],
        }
      }));
    }

    if (String(event.data).indexOf("game_end") == 0) {
      oArr = String(event.data).split("|");
      const jsn = JSON.parse(oArr[1]);
      dispatch(gameEnd(jsn));
    }

  }

  function getRandom(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    if (errorMSG !== "") {
      var tArr = errorMSG.split(" | ");
      setErrorTxtN(tArr[0]);
      setErrorTxtD(tArr[1]);
      handleClickOpen();
    }

  }, [errorMSG])

  useEffect(() => {
    if (phase === 1) {
      console.log("надо грузить пользовательские данные");
      dispatch(storeGetOwn());
      dispatch(profile());
    }
    if (phase === 2) {
      console.log("готов к подключению");
      setWsReady(true);
    }
  }, [phase]);

  useEffect(() => {

    if (gameData.inGame) {
      dispatch(resetTitleAnimation());
      dispatch(setAppSection("game"));
    }

    if (gameData.p1gameReady && gameData.p2gameReady && !gameData.bothStart) {
      dispatch(setBothStart());

      let o = {
        data: "both start",
        userInfo: gameData
      };

      if (refx.current.contentWindow != null) {
        refx.current.contentWindow.postMessage(o, "*");
      }
    }
  }, [gameData]);


  useEffect(() => {

    if (pmData == "ready") {

      let otherP;

      if (gameData.mode == "pc") {
        dispatch(setP2gameReady());
      } else {

        if (gameData.myPNum == 1) {
          dispatch(setP1gameReady());
          otherP = gameData.player2.id;
        }
        else {
          dispatch(setP2gameReady());
          otherP = gameData.player1.id;
        }

        dispatch(setWSAction({ com: "game_is_load", payload: otherP }));
      }
    }
  }, [pmData]);

  //диалог ошибок
  const [open, setOpen] = React.useState(false);
  const [errorTxtN, setErrorTxtN] = React.useState("");
  const [errorTxtD, setErrorTxtD] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(setError(""));
    setOpen(false);
  };

  /////////////////////////////////
  const rejectInvite = () => {
    dispatch(setWSAction({ com: "reject_invite", payload: "" }));
  };

  const accept_invite = () => {
    dispatch(setAmIFirstPlayer(false));
    dispatch(setWSAction({ com: "accept_invite", payload: "" }));
  }

  const [openBattleWin, setopenBattleWin] = React.useState((battleInvitation.length > 0 && !gameData.inGame) ? true : false);
  const [battleUser, setBattleUser] = React.useState({ name: "", level: "", });

  useEffect(() => {

    console.log(battleInvitation.length, gameData.inGame, "battleInvitation.length change")

    setopenBattleWin((battleInvitation.length > 0 && !gameData.inGame) ? true : false)

    if (battleInvitation.length > 0) {
      setBattleUser({ nick: battleInvitation[0].nick, level: battleInvitation[0].level });
    } else {
      setBattleUser({ nick: "", level: "" });
    }

  }, [battleInvitation.length]);

  const getCurrentSection = () => {
    switch (appSection) {
      case "loading":
        return <Loading />;
      case "intro":
        return <Intro />;
      case "login":
        return <Login />;
      case "registration":
        return <Registration />;
      case "nickname":
        return <Nickname />;
      case "main":
        return <Main />;
      case "game":
        return <Game refx={refx} mode={gameData.mode} />;
      case "subscription":
        return <Subscription />;
      case "passwordrec":
        return <PasswordRec />;
      case "newpass":
        return <NewPass />;
      default:
        break;
    }
  }

  return (
    <div className={`App-${mClass}`}>

      <div>
        <Dialog
          open={open}
          onClose={handleClose}
        >
          <div className='dialog-cont'>
            <div className='dialog-cont-pic'></div>
            <div className='dialog-cont-name'>{errorTxtN}</div>
            <div className='dialog-cont-dis'>{errorTxtD}</div>
            <div className='dialog-cont-btn'>
              <GradientButtonMini text="OK" padding="60" fontSize="16" onClick={handleClose} />
            </div>
          </div>
        </Dialog>
      </div>

      <div>
        <Dialog
          open={openBattleWin}
          onClose={handleClose}
        >
          <div className='dialog-cont'>
            <div className='dialog-cont-pic'></div>
            <div className='dialog-cont-name'>приглашение на поединок</div>
            <div className='dialog-cont-dis'>Пользователь <b>{battleUser.nick}</b> ({battleUser.level} уровень) приглашает вас на баттл</div>
            <div className='dialog-cont-btn'>
              <GradientButtonMini text="Принять" padding="60" fontSize="16" onClick={accept_invite} />
              <div className='dialog-cont-btn-space'></div>
              <GradientButtonMini text="Отказаться" padding="60" fontSize="16" onClick={rejectInvite} />
            </div>
          </div>
        </Dialog>
      </div>

      <div className='App-bg-cont-pc'>
        <div className='App-bg-cont-grass'></div>
        <div id={`background_tree_pic1-${mClass}`}></div>
        <div id={`background_tree_pic2-${mClass}`}></div>
        <div id={`background_tree_pic3-${mClass}`}></div>
        <div id={`background_tree_pic4-${mClass}`}></div>
      </div>

      <div className="App-bg-content">
        <AnimatePresence>
          {getCurrentSection()}
        </AnimatePresence>
      </div>

      <WebSocketContainer ready={wsReady} refx={refx} />

    </div>
  );
}

export default App;
