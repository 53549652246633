import "./FriendFinder.css";
import "../Button/Button.css"

import { useState, useEffect } from 'react';
import UserCard from "../UserCard/UserCard";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux';
import {  getOnlineUsers } from '../../Gdata';

function FriendFinder(props) {

    const dispatch = useDispatch();

    const { exit } = props

    const playersTest = useSelector(state => state.allPlayers);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            console.log('update users');
            dispatch(getOnlineUsers());
           
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    const [inputTxt, setTxt] = useState("Введите ник игрока");

    function txtOnFocus() {
        if (inputTxt === "Введите ник игрока") {
            setTxt("");
        }
    }

    function txtOnBlur() {
        if (inputTxt === "") {
            setTxt("Введите ник игрока");
        }
    }

    console.log("playersTest",playersTest);

    const filterPlayers = (inputTxt === "Введите ник игрока" || inputTxt === "") ? [...playersTest] : playersTest.filter(t => t.nick.toLocaleLowerCase().indexOf(inputTxt.toLocaleLowerCase()) != -1);

    console.log("filterPlayers", filterPlayers)

    let playerList = "";

    if (filterPlayers.length === 0) {

        playerList = (
            <div className="zeroGamers">
                Нет доступных игроков
            </div>
        )

    }
    else {
        playerList = (filterPlayers.map((player, i) =>
            <UserCard key={i} user_id={player.user_id} userName={player.nick} score={player.point} level={player.level} status={player.status.name} />
        ));
    }


    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }} className="friendFinder">
            <div className="friendFinder_window">

                <div className="friendFinder_window_exit btn" onClick={exit}></div>
                <div className="friendFinder_window_txt">Поиск игрока</div>
                <div className="friendFinder_window_input">

                    <div className="friendFinder_window_input_icon"></div>
                    <input className="friendFinder_window_input_txt" type="text" name="name" size="10" value={inputTxt} onChange={e => setTxt(e.target.value)} onFocus={txtOnFocus} onBlur={txtOnBlur} />

                </div>

                <div className="friendFinder_window_names">
                    {
                        playerList
                    }
                </div>

            </div>
        </motion.div>
    )
}

export default FriendFinder;