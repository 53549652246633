import "./Store2.css";

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion";
import StoreItem2 from "../../Base/StoreItem/StoreItem2";
import ScaleDiv from "../../Base/ScaleDiv/ScaleDiv";
import { sProfile, storeGetAll, storeGetOwn } from '../../Gdata';


function Store2({ w, h }) {

    const isMobile = useSelector(state => state.isMobile);
    const fruit = useSelector(state => state.userInfo.fruit);
    const store = useSelector(state => state.store);
   
    const userInfo = useSelector(state => state.userInfo);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(storeGetOwn());
        dispatch(storeGetAll());
    }, []);

    useEffect(() => {

        console.log("обновить профиль и список покупок")
        dispatch(storeGetOwn());
        dispatch(sProfile());

    }, [store.update])

    console.log("store.apples ", store.apples);

    let allItems = [];

    if (store.apples.length > 0) {

        for (let i = 0; i < store.apples.length; i++) {
            let el = store.apples[i];
            let o = {
                idx: el.idx,
                name: el.name,
                price: el.price,
                pic: el.pic,
                owned: false,
            }
            allItems.push(o);
        };
        for (let i = 0; i < allItems.length; i++) {
            for (let j = 0; j < store.owned.length; j++) {
                console.log(allItems[i].name, store.owned[j].name);
                if (allItems[i].name == store.owned[j].name) {
                    allItems[i].owned = true;
                }
            }
        }

    }

    var sContent = (allItems.length > 0) ? allItems.map((el, i) => <StoreItem2 idx={el.idx} key={i} price={el.price} name={el.name} active={(fruit >= el.price) ? true : false} owned={el.owned} />) : null;

    let currView;
    if (!isMobile) {
        currView = (
            <ScaleDiv contetWidth="1000" contetHeight="800" containerWidth={w} containerHeight={h}  >
                <div className="store2">
                    <div className="store2-tree-score-cont">
                        <div className="store2-tree-score">
                            <div className={"store2-tree-icon" + userInfo.level} >
                                <div className="store2-tree-txt">{userInfo.fruit}</div>
                            </div>
                        </div>
                        <div className={"store2-list"}>
                            {
                                sContent
                            }
                        </div>
                    </div>
                </div>
            </ScaleDiv>
        )
    } else {
        currView = (            
                <div className="store2">
                    <div className="store2-tree-score-cont-m">
                        <div className="store2-tree-score-m">
                            <div className={"store2-tree-icon" + userInfo.level} >
                                <div className="store2-tree-txt">{userInfo.fruit}</div>
                            </div>
                        </div>
                        <div className={"store2-list-m"}>
                            {
                                sContent
                            }
                        </div>
                    </div>
                </div>           
        )
    }




    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }} className="store2-container">
            {currView}
        </motion.div >
    )
}

export default Store2;