import "./Nickname.css";
import { useDispatch, useSelector } from 'react-redux';
import FContainer from "../../Base/FContainer/FContainer";
import GradientButton from "../../Base/GradientButton/GradientButton";
import GradientButtonMini from "../../Base/GradientButton/GradientButtonMini";
import { testReset, profile, setHomeSection, logOut, nickname, login, addApples, addHealth, minusHealth, levelUp, levelDown } from '../../Gdata';
import { motion } from "framer-motion";
import { useState, useEffect } from 'react';
import { useRef } from 'react';
import Api from "../../../services/Api";


function Nickname() {
    const inputRef = useRef(null);

    const dispatch = useDispatch();
    const isMobile = useSelector(state => state.isMobile);
    const user_nick = useSelector(state => state.userInfo.nick);
    const user_id = useSelector(state => state.userInfo.id);

    const [inputTxt, setTxt] = useState("");

    const mClass = isMobile ? "mobile" : "pc";

    const setNick = () => {
        const log = { nick: inputTxt }
        console.log("nick", log);
        dispatch(nickname(log));
    }

    const goObr = () => {
        window.open("https://obr.nd.ru/profile", "_blank")
    }

    const goMain = () => {
        dispatch(profile());
    }

    const exit = () => {
        console.log("exit");
        dispatch(logOut());
    }

    useEffect(() => {
        console.log("user_nick", user_nick)
        if (checkNick()) {
            inputRef.current.value = user_nick;
            setTxt(user_nick);
        }
    }, []);

    function checkNick() {
        if (user_nick != null && user_nick != "null" && user_nick != undefined && user_nick != "undefined") {
            return true;
        }
        return false;
    }

    function upLevelf() {
        dispatch(testReset({ user_id: user_id, action: "level_up" }));
    }

    function downLevelf() {
        dispatch(testReset({ user_id: user_id, action: "level_down" }));
    }

    function addHealthf() {
        dispatch(testReset({ user_id: user_id, action: "branche_add" }));
    }

    function minusHealthf() {
        dispatch(testReset({ user_id: user_id, action: "branche_del" }));
    }

    function addApplesf() {
        dispatch(testReset({ user_id: user_id, action: "fruit_add" }));
    }

    function playerReset() {
        dispatch(testReset({ user_id: user_id, action: "full" }));
    }

    //////////////////////////////////////
    let bottomBtns = (<div className="nickname-btns-cont">

        <div>
            <GradientButtonMini text="Полный сброс" fontSize="16" onClick={playerReset} />
            <div className="nickname-btns-cont"></div>
            <GradientButtonMini text="поднять уровень" fontSize="16" onClick={upLevelf} />
            <div className="nickname-btns-cont"></div>
            <GradientButtonMini text="опутить уровень" fontSize="16" onClick={downLevelf} />

        </div>
        <div>
            <GradientButtonMini text="добавить ветку" fontSize="16" onClick={addHealthf} />
            <div className="nickname-btns-cont"></div>
            <GradientButtonMini text="потерять ветку" fontSize="16" onClick={minusHealthf} />
            <div className="nickname-btns-cont"></div>
            <GradientButtonMini text="+500 яблок" fontSize="16" onClick={addApplesf} />
        </div>

        <div>
            <GradientButtonMini text="Назад" fontSize="16" onClick={goMain} />
            <div className="nickname-btns-cont"></div>
            <GradientButtonMini text="obr.ru" fontSize="16" onClick={goObr} />
            <div className="nickname-btns-cont"></div>
            <GradientButtonMini text="Выйти" fontSize="16" onClick={exit} />
        </div>

    </div>)
    //////////////////////////////////

    if (Api.isProd()) {
        bottomBtns = (<div className="nickname-btns-cont">
            <GradientButtonMini text="Назад" fontSize="16" onClick={goMain} />
            <div className="nickname-btns-cont"></div>
            <GradientButtonMini text="obr.ru" fontSize="16" onClick={goObr} />
            <div className="nickname-btns-cont"></div>
            <GradientButtonMini text="Выйти" fontSize="16" onClick={exit} />
        </div>)
    }





    const block = (inputTxt === "") ? true : false;

    const mView = (
        <div id="nickname" className={(!isMobile) ? "" : ""}>
            <div className={"nickname-logo nickname-logo-" + mClass}></div>
            <div className={"nickname-img nickname-img-" + mClass}></div>
            <div className={"nickname-text nickname-text-" + mClass}>Ваш ник в игре</div>
            <input className={"nickname-input-txt nickname-input-txt-" + mClass} ref={inputRef} type="text" id="name" name="name" size="10" onChange={newValue => { setTxt(newValue.target.value); }} />
            <GradientButton block={block} text="Сохранить ник" onClick={setNick} />

            {bottomBtns}

        </div>
    )

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }}
        >
            <FContainer>
                {mView}
            </FContainer>
        </motion.div>
    )
}

export default Nickname;