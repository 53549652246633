import "./StoreItem2.css";
import "../Button/Button.css";
import { useDispatch, useSelector } from 'react-redux';
import { storeBuy } from '../../Gdata';

function StoreItem2({ idx, price, name, active, owned }) {

    const dispatch = useDispatch();

    const apple = useSelector(state => state.user.fruit);

    const isMobile = useSelector(state => state.isMobile);
    const mClass2 = isMobile ? "-m" : "";
    const mClass = isMobile ? " storeItem2-mobile" : " storeItem2-mobile-pc";

    console.log(name,idx)

    const picCss = "storeItem2-price-img-i" + (idx-1);
    let activeCss = active ? " storeItem2-active" : " storeItem2-noActive";

    if (owned) {
        activeCss = " storeItem2-active";
    }

    function buyItem() {       

        if (apple >= price) {                        
            console.log("idx", idx);
            dispatch(storeBuy({ goods_id: idx }))
        }
    }

    return (
        <>
            <div className={"storeItem2" + mClass + activeCss}>
                <div className={"storeItem2-img " + picCss}></div>
                <div className="storeItem2-txt">{name}</div>
                <div className="storeItem2-box"></div>
                <div className="storeItem2-price">
                    <div className={"storeItem2-price-img"+mClass2}></div>
                    <div className="storeItem2-price-txt">{price}</div>
                </div>
                <div className="storeItem2-box"></div>
                {
                    owned ? <div className={"storeItem2-btn2"+mClass2}>КУПЛЕНО</div> : <div className={"storeItem2-btn"+mClass2} onClick={buyItem}>КУПИТЬ</div>
                }

            </div>
            <div className="storeItem2-line"></div>
        </>
    )
}

export default StoreItem2;