import CircularProgress from '@mui/material/CircularProgress';
import './Loading.css';

function Loading() {
    return (
        <div id='loadingContainer'>
        <CircularProgress color="success"  />
        </div>
    )
}

export default Loading;