import './FContainer.css';
import PropTypes from 'prop-types';

function FContainer({children}) {
    return (
        <div className="fcontainer">
            {children}
        </div>
    )
}
FContainer.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.element.isRequired
      ]),
}
export default FContainer;


  
 