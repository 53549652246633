import "./UserCard.css";
import Engine from "../../../services/Engine";
import { useDispatch } from 'react-redux';
import { setFriendForWait, setWSAction, setAmIFirstPlayer } from '../../Gdata';
function UserCard(props) {

    const { userName, level, score, user_id, status } = props;
    const dispatch = useDispatch();


    function setFriendWaiter() {

        var o = {
            id: user_id,
            level: level,
            nick: userName,
            score: score,
            status: status
        }

        console.log("!!!0!!!", o)

        dispatch(setFriendForWait(o));
        dispatch(setAmIFirstPlayer(true));
        dispatch(setWSAction({ com: "send_invite", payload: user_id }));
    }

    const cssDisable = (status == "свободен") ? "" : " userCard_cssDisable";


    return (
        <div className={"userCard" + cssDisable}>
            <div className="userCard_cont btn" onClick={setFriendWaiter}>
                <div className="userCard_photo"></div>
                <div className="userCard_txt">
                    <div className="userCard_txt1">{userName + " (" + status + ")"}</div>
                    <div className="userCard_txt2">{level + " уровень " + score + " " + Engine.num_word(score, ["балл", "балла", "баллов"])} </div>
                </div>
            </div>
            <div className="userCard_Bottom"></div>
        </div>
    )

}

export default UserCard;