import * as React from 'react';
import "./Card3d.css";

function Card3d({ txt }) {

    let tArr = txt.split("$");
   
    const [css, setCss] = React.useState("c3-card");

    function changeCss() { 
        if (css == "c3-card") {           
            setCss("c3-card is-flipped");
        } else {            
            setCss("c3-card");
        }
    }

    return (
        <div className="c3-scene" onClick={changeCss}>
            <div className={css} >
                <div className="c3-card__face c3-card__face--front">
                    <div className="c3-txt-cont">
                        <div className="c3-txt-cont2">
                            <div dangerouslySetInnerHTML={{ __html: tArr[0] }} />
                        </div>
                    </div>
                    <div className="c3-txt-btn">
                        Показать текст
                    </div>
                </div>
                <div className="c3-card__face c3-card__face--back">
                    <div className="c3-txt-cont">
                        <div className="c3-txt-cont2">
                            <div dangerouslySetInnerHTML={{ __html: tArr[1] }} />
                        </div>
                    </div>
                    <div className="c3-txt-btn">
                        Скрыть текст
                    </div>
                </div>
            </div>
        </div>

    )


}

export default Card3d;