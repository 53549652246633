import "./GradientButton.css";
import "../Button/Button.css";
import { useSelector } from 'react-redux';

function  GradientButton ({text, block, ...buttonProps}) {    
    
    
    const isMobile = useSelector(state => state.isMobile);
    const mClass = isMobile ? "gradient-button-mobile" : "gradient-button-pc";

    const blockClass = block ? " block" : "";

    return (
        <div className={"btn gradient-button " + mClass+blockClass} {...buttonProps}>
            {text}
        </div>
    )
}
export default GradientButton;