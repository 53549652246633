import { useSelector } from 'react-redux';
import Header from '../../Base/Header/Header';
import { Profile, Achievements, Rating, Store2, Tree } from '../index'
import { useRef, useState, useEffect } from 'react';

import "./Main.css";

function Main() {

    const mainSection = useSelector(state => state.mainSection);
    const ver = useSelector(state => state.version);
    const elementRef = useRef(null);

    const [heightC, setHeightC] = useState(0);
    const [weightC, setWeightC] = useState(0);

    const showVer = "";/*(
        <div id="ver">
            {ver}
        </div>
    )*/

    //page resize
    useEffect(() => {
        const handleResize = () => {
            setHeightC(elementRef.current.offsetHeight);
            setWeightC(elementRef.current.offsetWidth);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);


    let mView = null;
    switch (mainSection) {
        case "profile":
            mView = <Profile h={heightC} w={weightC} />
            break;
        case "rating":
            mView = <Rating h={heightC} w={weightC} />
            break;
        case "achievements":
            mView = <Achievements h={heightC} w={weightC} />
            break;
        case "store":
            mView = <Store2 h={heightC} w={weightC} />
            break;
        case "tree":
            mView = <Tree h={heightC} w={weightC} />
            break;
        default:
            mView = <Profile h={heightC} w={weightC} />
            break;
    }

    return (
        <div className="Main2">
            <div className='Main2-header'>
                <Header />
            </div>
            <div className='Main2-content' ref={elementRef}>
                {showVer}
                {mView}
            </div>

        </div>
    )
}

export default Main;