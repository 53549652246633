import "./Rating.css";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux';
import RatingItem from "../../Base/RatingItem/RatingItem";
import * as React from 'react';
import ScaleDiv from "../../Base/ScaleDiv/ScaleDiv";
import { useEffect } from 'react';
import { ratingFull, ratingWeek } from '../../../components/Gdata';

function Rating({ w, h }) {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(ratingFull());
        dispatch(ratingWeek());
    }, [])

    const raitingArr = useSelector(state => state.rainting);
    const isMobile = useSelector(state => state.isMobile);
    const mClass = isMobile ? "mobile" : "pc";

    const [section, setSection] = React.useState(1);

    function go(par) {
        if (par === "all") {
            setSection(1)
        } else {
            setSection(2)
        }
    }

    let line0Width = (section == 2) ? "420px" : "0px";
    if (isMobile){
        line0Width = (section == 2) ? "270px" : "0px";
    }

    const style1 = (section == 1) ? "rbtn-active" : "rbtn-passive";
    const style2 = (section == 2) ? "rbtn-active" : "rbtn-passive";

    let playersArr = (section == 1) ? raitingArr.all : raitingArr.week;
    let currentRating = [];

    for (let i = 0; i < playersArr.length; i++) {

        const el = playersArr[i];

        if (playersArr[i].nick != null) {
            currentRating.push({
                nick: el.nick,
                level: el.level,
                point: el.point,
            })
        }
    }

    function getPrevWeek() {

        var date = new Date(),
            targetDay = 1, // пятница, начиная с вс=0
            targetDay2 = 0, // пятница, начиная с вс=0
            targetDate = new Date(),
            targetDate2 = new Date(),
            delta2 = targetDay2 - date.getDay(),
            delta = targetDay - date.getDay();

        if (delta >= 0) { targetDate.setDate(date.getDate() + delta) }
        else { targetDate.setDate(date.getDate() - 7 + delta) }

        targetDate2.setDate(date.getDate() + delta2);

        var targetNumber = targetDate.getDate();
        var targetNumber2 = targetDate2.getDate();
        var targetMonth = targetDate.getMonth();
        var targetMonth2 = targetDate2.getMonth();
        switch (targetMonth) {
            default:
            case 0: targetMonth = 'января'; break;
            case 1: targetMonth = 'февраля'; break;
            case 2: targetMonth = 'марта'; break;
            case 3: targetMonth = 'апреля'; break;
            case 4: targetMonth = 'мая'; break;
            case 5: targetMonth = 'июня'; break;
            case 6: targetMonth = 'июля'; break;
            case 7: targetMonth = 'августа'; break;
            case 8: targetMonth = 'сентября'; break;
            case 9: targetMonth = 'октября'; break;
            case 10: targetMonth = 'ноября'; break;
            case 11: targetMonth = 'декабря'; break;
        }
        switch (targetMonth2) {
            default:
            case 0: targetMonth2 = 'января'; break;
            case 1: targetMonth2 = 'февраля'; break;
            case 2: targetMonth2 = 'марта'; break;
            case 3: targetMonth2 = 'апреля'; break;
            case 4: targetMonth2 = 'мая'; break;
            case 5: targetMonth2 = 'июня'; break;
            case 6: targetMonth2 = 'июля'; break;
            case 7: targetMonth2 = 'августа'; break;
            case 8: targetMonth2 = 'сентября'; break;
            case 9: targetMonth2 = 'октября'; break;
            case 10: targetMonth2 = 'ноября'; break;
            case 11: targetMonth2 = 'декабря'; break;
        }
        //var targetYear = targetDate.getFullYear();
        // console.log("getprevWeek", targetNumber + ' ' + targetMonth + ' ' + targetYear + ' года', targetNumber2)

        const str = "Рейтинг с " + targetNumber + " " + targetMonth + " по " + targetNumber2 + " " + targetMonth2

        return str;
    }


    let contR = (
        <div className="rating0">
            Рейтинг ещё не сформирован
        </div>
    );

    if (currentRating.length > 0) {
        contR = currentRating.map((player, i) =>
            <RatingItem key={i} num={i} userName={player.nick} score={player.point} level={player.level} />
        )
    }


    let currView;
    if (!isMobile) {
        currView = (
            <ScaleDiv contetWidth="900" contetHeight="760" containerWidth={w} containerHeight={h} isScale="true" >

                <div className="all">
                    <div className={"rating rating-" + mClass}>
                        <div className={"rbtn " + style1} onClick={() => go("all")}>
                            Рейтинг игроков
                        </div>

                        <div className="rating-head-btns-gap">
                        </div>

                        <div className={"rbtn " + style2} onClick={() => go("weak")}>
                            Недельный рейтинг
                        </div>
                    </div>

                    <div className="rating-head-line">
                        <div className="rating-head-line-item0" style={{ width: line0Width }}></div>
                        <div className="rating-head-line-item"></div>
                    </div>

                    {(section == 2) ? (
                        <div className="rating-head-txt">
                            {getPrevWeek()}
                        </div>) : <div className="rating-head-txt">Общий рейтинг</div>
                    }

                    <div className="rating-list">
                        {
                            contR
                        }
                    </div>
                </div>
            </ScaleDiv>
        );
    } else {
        currView = (           

                <div className="all">
                    <div className={"rating rating-" + mClass}>
                        <div className={"rbtn " + style1} onClick={() => go("all")}>
                            Рейтинг игроков
                        </div>

                        <div className="rating-head-btns-gap">
                        </div>

                        <div className={"rbtn " + style2} onClick={() => go("weak")}>
                            Недельный рейтинг
                        </div>
                    </div>

                    <div className="rating-head-line-m">
                        <div className="rating-head-line-item0-m" style={{ width: line0Width }}></div>
                        <div className="rating-head-line-item-m"></div>
                    </div>

                    {(section == 2) ? (
                        <div className="rating-head-txt">
                            {getPrevWeek()}
                        </div>) : <div className="rating-head-txt">Рейтинг за всё время</div>
                    }

                    <div className="rating-list-m">
                        {
                            contR
                        }
                    </div>
                </div>            
        );
    }


    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }} className="rating-container">

            {currView}

        </motion.div>
    )
}

export default Rating;