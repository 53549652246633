class Engine {

    static mobileViewportHack = (width) => {
        if (this.isMobile) {
            this.setUpMobile(width);
        }
    }
    static setUpMobile = (width) => {
        function apply_viewport(event) {
            var ww = window.screen.width;
            var mw = width; // min width of site
            var ratio = ww / mw; //calculate ratio
            var viewport_meta_tag = document.getElementById('viewport');
            if (ww < mw) { //smaller than minimum size            
                viewport_meta_tag.setAttribute('content', 'initial-scale=' + ratio + ', maximum-scale=' + ratio + ', minimum-scale=' + ratio + ', user-scalable=no, width=' + mw);
            }
            else { //regular size          
                var landw = Math.min(window.innerWidth, window.outerWidth, ww);
                viewport_meta_tag.setAttribute('content', 'initial-scale=1.0, maximum-scale=1, minimum-scale=1.0, user-scalable=yes, width=' + landw);
            }
        }
        //ok, i need to update viewport scale if screen dimentions changed
        window.addEventListener('resize', function () {
            apply_viewport();
        });
        apply_viewport();
    }
    static isMobile = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
            //console.log("мобильный")
            return true;
        }

       return isIpadOS();

        function isIpadOS() {
            return navigator.maxTouchPoints &&
                navigator.maxTouchPoints > 2 &&
                /MacIntel/.test(navigator.platform);
        }

        //console.log("пк")
        //return false;
    }
    static replaceAll = (str, find, replace) => {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);

        function escapeRegExp(string) {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        }
    }
    static fixSqlText = (str) => {
        return this.replaceAll(str, "'", "''");
    }
    static loginfix = (str) => {
        let newstr = str;
        newstr = newstr.replace(/\s/g, '');
        return newstr;
    }

    static scaleCalc = (w, h, cw, ch) => {

        var o = {
            firefox: false,
            scx: 0
        }

        var iw = cw;
        var ih = ch;
        var pRatio = window.devicePixelRatio || 1;
        var xRatio = iw / w;
        var yRatio = ih / h;
        var sRatio = Math.min(xRatio, yRatio);

        var scx = (pRatio * sRatio) / window.devicePixelRatio;

        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            o.firefox = true;
        }
        o.scx = scx.toFixed(2) - 0.02;
        o.w = Math.floor(w * sRatio) + 'px';
        o.h = Math.floor(h * sRatio) + 'px';

        return o;
    }

    static num_word = (value, words) => {
        value = Math.abs(value) % 100;
        var num = value % 10;
        if (value > 10 && value < 20) return words[2];
        if (num > 1 && num < 5) return words[1];
        if (num == 1) return words[0];
        return words[2];
    }

}

export default Engine;