import "./AchievementItem.css";

function AchievementItem({ txt }) {

    /*
        <a href=\\https://dictionary.cambridge.org/dictionary/english-russian/remind-sb-of-sth-sb?q=remind+sb\\ target=\\_blank\\>remind SB of – напомнить кому-то о чём-то/ком-то</a>
    */

    let newTxt = txt;

    newTxt = newTxt.replaceAll('\\', "'");

    //newTxt = new DOMParser().parseFromString(newTxt, "text/xml");
    console.log("newTxt", newTxt)
    //newTxt = newTxt.firstChild.innerHTML;
   

    return (
        <div className="achievement-page-content-element">
            <div className="achievement-page-content-title">
                
                <div dangerouslySetInnerHTML={{__html: newTxt}} /> 
                
            </div>
        </div>
    )


}

export default AchievementItem;