import "./Login.css";
import "../Intro/Intro.css";
import { useDispatch, useSelector } from 'react-redux';
import FContainer from "../../Base/FContainer/FContainer";
import GradientButton from "../../Base/GradientButton/GradientButton";
import { login, setAppSection, setIsLoading } from '../../Gdata';
import { motion } from "framer-motion";
import { useState, useEffect } from 'react';
import Engine from '../../../services/Engine';

function Login() {

    const dispatch = useDispatch();

    const isMobile = useSelector(state => state.isMobile);
    const isLoading = useSelector(state => state.xmlR.loading);
    const mClass = isMobile ? "mobile" : "pc";

    const [inputTxt1, setTxt1] = useState("");
    const [inputTxt2, setTxt2] = useState("");

    const [block, setBlock] = useState(true);

    useEffect(() => {

        if (inputTxt1 !== "" && inputTxt2 !== "" && inputTxt1.indexOf("@") !== -1 && inputTxt1.indexOf(".") !== -1 && isLoading===false) {
            setBlock(false);
        } else {
            setBlock(true);
        }
    }, [inputTxt1, inputTxt2, isLoading]);

    const goLogin = (e) => {
        // console.log("goNickName")
        e.preventDefault();
        const log = { email: Engine.loginfix(inputTxt1), password: Engine.loginfix(inputTxt2) }

        console.log("log", log);
        dispatch(setIsLoading(true));
        dispatch(login(log));
        // dispatch(setAppSection("nickname"));
    }

    const goReg = () => {
       /* if (window.location.href.indexOf("obrdev") !== -1 || window.location.href.indexOf("localhost") !== -1) {
            window.open("https://ltc-english.obrdev.ru/gardenbattle/registration", "_self");
        }else{
            window.open("https://lms.ltc-english.ru/gardenbattle/registration", "_self"); 
        } 
        */
        dispatch(setAppSection("registration"));       
    }

    const forgotPassword= () => {
        //window.open("https://lms.ltc-english.ru/password-forgot?gb", "_self"); 
        dispatch(setAppSection("passwordrec"));
        //dispatch(setAppSection("newpass"));    
    }

    const mView = (
        <div id="intro" className={(!isMobile) ? "" : ""}>

            <div className={"intro-logo intro-logo-" + mClass}></div>

            <div className="loginTxt">ЛОГИН</div>
            <div>
                <input className={"nickname-input-txt nickname-input-txt-" + mClass} type="text" id="email" name="email" size="10" onChange={newValue => { setTxt1(newValue.target.value); }} />
            </div>
            <div className="loginTxt">ПАРОЛЬ</div>
            <input className={"nickname-input-txt nickname-input-txt-" + mClass} type="password" id="name" name="name" size="10" onChange={newValue => { setTxt2(newValue.target.value); }} />
           
            <div className="loginTxtBtn2" onClick={forgotPassword}>Забыли пароль?</div>

            <div className="marginBtn">
                <GradientButton block={block} text="Войти" onClick={goLogin}></GradientButton>
            </div>
            <div className="loginTxtBtn" onClick={goReg}>ЗАРЕГИСТРИРОВАТЬСЯ</div>
        </div>
    )

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }}
        >
            <FContainer>
                {mView}
            </FContainer>
        </motion.div>
    )
}

export default Login;