import "./Achievements.css";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import ScaleDiv from "../../Base/ScaleDiv/ScaleDiv";
import { getAchieve } from '../../../components/Gdata';
import AchievementItem from "../../Base/AchievementItem/AchievementItem";
import Card3d from "../../Base/Card3D/Card3d";

function Achievements({ w, h }) {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getAchieve());
    }, [])

    const achieveArr = useSelector(state => state.achieve);
    const maxAchieve = useSelector(state => state.maxAchieve);
    console.log("achieveArr",achieveArr);

    const isMobile = useSelector(state => state.isMobile);
    const mClass = isMobile ? "mobile" : "pc";

    const [page, setPage] = useState(1);
    const [pageX, setPageX] = useState(0);

    const [cPage, setCPage] = useState(0);



    function set(newpage) {
        setPage(newpage);
        setPageX(((newpage - 1) * (-320)));
    }

    const arrtxt = achieveArr;

    //const arrtxt = [];
    /*  arrtxt = [
          '<p><b>remind SB 1 of</b> – напомнить кому-то о чём-то/ком-то</p> <p>to make someone think of something or someone else – напоминать кому-либо что-либо/кого-либо</p> <p><i>Harry reminds me of my father.</i></p> <p><i>This song reminds me of our trip to Spain.</i> </p> $ <p>Emily 1 and Sarah met in college and quickly became inseparable. Emily loved spending time with Sarah because she <b>reminded</b> her of the importance of following her dreams.</p>',
          '<p><b>remind SB 2 of</b> – напомнить кому-то о чём-то/ком-то</p> <p>to make someone think of something or someone else – напоминать кому-либо что-либо/кого-либо</p> <p><i>Harry reminds me of my father.</i></p> <p><i>This song reminds me of our trip to Spain.</i> </p> $ <p>Emily 2 and Sarah met in college and quickly became inseparable. Emily loved spending time with Sarah because she <b>reminded</b> her of the importance of following her dreams.</p>',
          '<p><b>remind SB 3 of</b> – напомнить кому-то о чём-то/ком-то</p> <p>to make someone think of something or someone else – напоминать кому-либо что-либо/кого-либо</p> <p><i>Harry reminds me of my father.</i></p> <p><i>This song reminds me of our trip to Spain.</i> </p> $ <p>Emily 3 and Sarah met in college and quickly became inseparable. Emily loved spending time with Sarah because she <b>reminded</b> her of the importance of following her dreams.</p>',
      ]; 
      */


    function nextPage() {

        let nPage = cPage + 1;

        if (nPage >= arrtxt.length) {
            nPage = 0;
        }

        console.log(nPage, arrtxt.length);
        setCPage(nPage);
    }

    function prevPage() {

        let nPage = cPage - 1;

        if (nPage < 0) {
            nPage = arrtxt.length - 1;
        }

        console.log(nPage, arrtxt.length);
        setCPage(nPage);
    }

    let contentA;
    let btnEnabled = "";

    if (arrtxt.length == 0) {
        contentA = (
            <div className="achievement-page-content-element-0">
                У вас пока нет достижений
            </div>);

    } else {
        contentA = arrtxt.map((el, i) => <AchievementItem key={i} txt={el.micromodule.description} />);
    }

    
    if (arrtxt.length < 2) {
        btnEnabled = " hide";
    }

    let currView2;
    if (arrtxt.length == 0) {
        currView2 = (
            <div className="page1">
                <div className="achievement-page-content" >
                    <div className="achievement-page-content-elements">
                        {
                            contentA
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        currView2 = <Card3d txt={arrtxt[cPage].micromodule.description} />
    }

    const aTxt = "У вас " + arrtxt.length + " " + num_word(arrtxt.length, ['достижение', 'достижения', 'достижений']) + " из "+ maxAchieve;
    function num_word(value, words) {
        value = Math.abs(value) % 100;
        var num = value % 10;
        if (value > 10 && value < 20) return words[2];
        if (num > 1 && num < 5) return words[1];
        if (num == 1) return words[0];
        return words[2];
    }

    let currView;
    if (!isMobile) {
        currView = (
            <ScaleDiv contetWidth="950" contetHeight="760" containerWidth={w} containerHeight={h} isScale="!false" >

                <div className="achievements_header">{aTxt}</div>

                <div className={"achievements achievements-" + mClass}>

                    <div className={"achievement-page-left btn" + btnEnabled} onClick={prevPage}></div>

                    <div className="achievement-page">

                        <div className="page4"></div>
                        <div className="page3"></div>
                        <div className="page2"></div>
                        {
                            currView2
                        }
                    </div>
                    <div className={"achievement-page-right btn" + btnEnabled} onClick={nextPage}></div>

                </div>

            </ScaleDiv>
        );
    } else {
        currView = (
            <div>

                <div className="achievements_header_m">{aTxt}</div>
                <div className={"achievements achievements-" + mClass}>

                    <div className={"achievement-page-left btn" + btnEnabled} onClick={prevPage}></div>
                    <div className="achievement-page">
                        <div className="page4"></div>
                        <div className="page3"></div>
                        <div className="page2"></div>

                        {
                            currView2
                        }

                    </div>
                    <div className={"achievement-page-right btn" + btnEnabled} onClick={nextPage}></div>
                </div>
            </div>
        );
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
                duration: 0.3,
            }} className="achievements-container">

            {currView}

        </motion.div>
    )
}

export default Achievements;